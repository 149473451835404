<div class="page" style="min-height: 100vh; padding-top: 10px;">
    <div style="margin-top: 0px; margin-bottom: 20px;">
        <div data-role="stepper" data-steps="3" data-step="2" data-view="diamond" data-cls-step="rounded" data-cls-complete="bg-green" data-cls-current="bg-blue">
        </div>
    </div>
    <div class="entete" *ngIf="reservation">
        <app-display-reservation-description [reservation]="reservation">

        </app-display-reservation-description>
        <div class="shadow-1" style="padding:20px; padding-top: 15px; margin-top: 20px">
            <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                <h3 style="margin:0; padding:0;">
                    {{'Responsable de la réservation' | traduction}}
                </h3>
                <div style="color: #444;">
                    <small>
                        {{'Les informations suivantes sont strictement confidentielles et ne seront ni utilisées ni vendues' | traduction}}
                    </small>
                </div>
                <div class="fg-bleu">
                    <b>{{'Tous les champs sont obligatoires' | traduction}}</b>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="cell-lg-6">
                        <div>
                            <input formControlName="nom" data-role="input" [attr.data-prepend]="'Noms' | traduction">
                        </div>
                    </div>
                    <div class="cell-lg-6">
                        <div>
                            <input formControlName="prenom" data-role="input" [attr.data-prepend]="'Prenoms' | traduction">
                        </div>
                    </div>
                    <div class="cell-lg-3">
                        <div>
                            <input formControlName="indicatif" data-role="input" [attr.data-prepend]="'Indicatif' | traduction">
                        </div>
                    </div>
                    <div class="cell-lg-3">
                        <div>
                            <input type="number" formControlName="tel" data-role="input" [attr.data-prepend]="'Tel' | traduction">
                        </div>
                    </div>
                    <div class="cell-lg-6">
                        <div>
                            <input type="email" formControlName="email" data-role="input" [attr.data-prepend]="'Email' | traduction">
                        </div>
                    </div>
                    <div class="cell-lg-6">
                        <div>
                            <select formControlName="typepiece" data-role="select" [attr.data-prepend]="'Pièce d`identification' | traduction">
                                <option value="cni">
                                    {{"Carte Nationale d'Identité" | traduction}}
                                </option>
                                <option value="passeport">
                                    {{'Passeport' | traduction}}
                                </option>
                                <option value="sejour">
                                    {{'Carte de séjour' | traduction}} 
                                </option>
                                <option value="permis">
                                    {{'Permis de conduire' | traduction}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="cell-lg-6">
                        <div>
                            <input formControlName="numero" data-role="input" [attr.data-prepend]="'N° Pièce' | traduction">
                        </div>
                    </div>
                </div>
            </form>
            <div style="margin-top: 20px">
                <button [disabled]="form.invalid" (click)="onFormSubmit()" class="button warning" style="background-color:  rgb(48, 164, 221); color: #fff;">
                    {{'Enregistrer les informations' | traduction}}
                </button>
            </div>
        </div>
        <div style="margin-top: 20px">
            <button (click)="revenir()" class="button">
                {{'Revenir à la réservation' | traduction}}
            </button>
        </div>

    </div>
    <div class="contenu">

    </div>
</div>