<div style="min-height: 100vh;">
    <div class="page" *ngIf="divertissement">
        <div class="entete">
            <div class="row">
                <div class="cell-lg-9">
                    <div class="titre">
                        {{langue.toUpperCase() === 'ENG' ? (divertissement.titreENG ? divertissement.titreENG: divertissement.titre) : divertissement.titre}}
                    </div>
                    <div [innerHTML]="langue.toUpperCase() === 'ENG' ? (divertissement.descriptionENG ? divertissement.descriptionENG: divertissement.description) : divertissement.description" class="sous-titre text-leader2" style="margin-top: 20px;">

                    </div>
                    <div>
                        <div style="margin-top: 10px;">
                            <a *ngIf="divertissement.latitude && divertissement.longitude" class="showOnMObile " style="color: rgb(48, 164, 221); font-size: 0.8em;" (click)="ouvrirGoogleMap()" target="_blank">
                                {{'Ouvrir sur Google Map' | traduction}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="cell-lg-3 text-right hideOnMObile">
                    <div *ngIf="divertissement.latitude && divertissement.longitude" style="text-align: center; margin-top: 0px;">
                        <div style="font-size: 2.5em;">
                            <span class="mif-location" style="color: rgb(48, 164, 221);"></span>
                        </div>
                        <div>
                            <a class="text-leader2 map" style="color: #303030;" (click)="ouvrirGoogleMap()" target="_blank">
                                {{'Ouvrir sur Google Map' | traduction}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contenu" style="padding-top: 5vh">
            <div class="row">
                <div *ngFor="let divertissementItem of divertissementItems" class="cell-lg-4">
                    <app-display-divertissement-item [divertissementItem]="divertissementItem"></app-display-divertissement-item>
                </div>
            </div>
        </div>
    </div>
    <div class="contenu-images" style="">
        <div class="row ">
            <div *ngFor="let image of divertissement.images " class="cell-lg-3 ">
                <div class="trap-image " [ngStyle]="{ 'background-image': 'url(' + image + ')'} ">

                </div>
            </div>
        </div>
    </div>
</div>