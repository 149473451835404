<div class="page-transport">
    <div class="entete" style="background-color: #eee;">

        <div class="entete__background">
        </div>
        <div class="entete__contenu">

            <div style="margin-left: -15px; margin-bottom: -20px;">
                <ul class="breadcrumbs" style="background-color: rgba(0,0,0,0);">
                    <li class="page-item">
                        <a routerLink="/offres/transport" class="page-link">
                            Transport
                        </a>
                    </li>
                    <li class="page-item" *ngIf="trajet">
                        <a class="page-link">
                            {{description(trajet)}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="row">
                <div class="cell-lg-12">
                    <div class="titre">
                        {{'Sélectionner la compagnie de voyage' | traduction}}
                    </div>
                    <div *ngIf="trajet" class="sous-titre text-leader2">
                        <div *ngIf="trajet.villeDepart === trajet.villeArrivee">
                            {{trajet.villeDepart}}
                        </div>
                        <div *ngIf="trajet.villeDepart !== trajet.villeArrivee">
                            {{trajet.villeDepart}} - {{trajet.villeArrivee}}
                            <span class="petit-float">
                                <span class="mif-meter">

                                </span>
                                <span style="margin-left: 10px;">
                                    {{trajet.duree ? trajet.duree: 'Aucune information sur la durée du trajet'}}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!choixGare" class="contenu" style="margin-top: 20px;">
        <div class="row">
            <div class="col-lg-3" *ngFor="let depart of departs; let i=index">
                <div (click)="choisirAgenceDepart(depart)">
                    <app-display-agence [prix]="depart.prix" [prixAR]="depart.prixAR"
                        [agence]="depart.agence"></app-display-agence>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="choixGare" class="contenu" style="margin-top: 20px;">
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <div class="text-leader2" style="margin-bottom: 20px;">
                        {{"Veuillez choisir l'agence" | traduction}}
                    </div>
                    <div class="row">
                        <div class="col-lg-4" *ngFor="let gare of gares; let i=index">
                            <div (click)="reservationWithGare(departChoisi, gare)">
                                <app-display-gare [gare]="gare"></app-display-gare>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>