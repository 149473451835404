<div class="page">
    <app-rubriques></app-rubriques>
    <app-carousel></app-carousel>
    <div [ngClass]="{'surMobile': mobile}">
        <app-accueil-sejours></app-accueil-sejours>
        <app-accueil-divertissements></app-accueil-divertissements>
        <app-accueil-hebergements></app-accueil-hebergements>
        <app-accueil-transports></app-accueil-transports>
    </div>
</div>
<div>
    <app-news-letter></app-news-letter>
</div>