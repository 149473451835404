import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gare-edit',
  templateUrl: './gare-edit.component.html',
  styleUrls: ['./gare-edit.component.scss']
})
export class GareEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
