<div style="padding: 10%; padding-top: 7%;">
    <div>
        <h1>
            Charte de confidentialité Trap Your Trip Sarl
        </h1>
        <p>
            La présente charte de confidentialité et de respect de la vie privée concernant les données des clients et utilisateurs en ligne (« Charte de confidentialité ») s'applique à à la plateforme de réservation en ligne trapyourtrip.com conçue et développée
            par SMART BLUE TECHNOLOGY SARL.
        </p>
        <p>
            La charte régit le traitement des données à caractère personnel que nous recueillons auprès de nos clients, sur notre site Web et de nos utilisateurs en ligne (« vous » ou « votre »).
        </p>
        <p>
            Aux fins de la présente Charte de confidentialité, le terme de « Prestataire partenaire » désigne le fournisseur de l'hébergement (par ex. l'hôtel, le motel, l'appartement, la chambre d'hôtes, le propriétaire), de centre de loisirs (par ex. le parc (à
            thème), le musée, la visite touristique), du transport (par ex. de la location de voiture, de la croisière, du billet de train, du transfert aéroport, de la visite en car, du transfert, de transport interurbain), le tour-opérateur, rendu disponible
            de temps à autre sur la Plateforme pour une Réservation/Commande de Prestation (B2B ou B2C).
        </p>
        <p>
            Le terme « Données à caractère personnel » désigne les informations qui se réfèrent à une personne identifiée ou identifiable de manière directe ou non, et qui font l’objet d’un traitement informatique, tels que vos nom, numéro de téléphone, adresse électronique,
            adresse IP.
        </p>

    </div>
    <div>
        <h2>
            1. Informations recueillies
        </h2>
        <p>
            Nous pouvons être amenés à vous demander de nous fournir des Données à caractère personnel lorsque :
        </p>
        <ul>
            <li>
                Vous utilisez notre site web et notre application ;
            </li>
            <li>
                Vous effectuez vos réservations, demandez des services d'assistance, téléchargements;
            </li>
            <li>
                Vous créez et gérez un compte ;
            </li>
            <li>
                Vous fournissez un témoignage, vous attribuez des notes aux services utilisés ;
            </li>
            <li>
                Vous vous abonnez à des bulletins, e-mails marketing et promotionnels ou autres ressources ;
            </li>
            <li>
                Vous nous contactez
            </li>
        </ul>
        <p>
            Les Données à caractère personnel que nous demandons peuvent comprendre vos noms, adresse e-mail, adresse de localisation, coordonnées téléphoniques, identifiants utilisateur et mots de passe. Elles peuvent également comprendre des informations professionnelles,
            telles que l'intitulé du poste et d'autres informations commerciales ou sur la société. Nous pouvons également recueillir des données démographiques telles que votre âge, votre sexe, vos centres d'intérêt et vos préférences. Les formulaires
            que vous choisirez de remplir indiqueront si les informations demandées sont obligatoires ou non. Si vous effectuez un achat, nous pouvons vous demander votre numéro de carte de crédit et vos coordonnées de facturation.
        </p>
        <p>
            Nous pouvons également recueillir des informations concernant vos visites sur nos sites Web, notamment les pages que vous consultez, les liens et les publicités sur lesquels vous cliquez, les mots-clés que vous saisissez, ainsi que les autres actions
            que vous effectuez en lien avec les services de Trap Your Trip SARL. Nous pouvons par ailleurs recueillir certaines informations provenant du navigateur utilisé pour accéder à notre site Web, comme votre adresse IP, l'identificateur du périphérique,
            le type et la langue du navigateur, les heures d'accès, l'URL du site Web qui vous a renvoyé vers notre site Web et les URL auxquelles vous avez accédé en cliquant sur un lien figurant sur notre site.
        </p>
    </div>
    <div>
        <h2>
            2. Finalité du traitement
        </h2>
        <p>
            Trap Your Trip SARL peut utiliser vos Données à caractère personnel pour :
        </p>
        <ul>
            <li>
                fournir les, services et assistance ou réaliser les transactions que vous avez demandées ;
            </li>
            <li>
                vous adresser des communications telles que des informations sur la sécurité des produits, le statut de votre transaction et notamment les notifications concernant les produits de votre panier et les confirmations de réservation, des informations sur
                les offres spéciales proposées sur notre site, des informations sur les produits, solutions et services proposés par Trap Your Trip, des événements, des offres promotionnelles et des enquêtes ;
            </li>
            <li>
                faciliter votre utilisation de notre site Web, ;
            </li>
            <li>
                personnaliser, analyser et améliorer nos services, nos technologies, nos communications et nos relations avec vous ;
            </li>
            <li>
                mieux comprendre nos clients et utilisateurs en ligne, notamment l'établissement de profils en fonction des intérêts et des interactions avec notre site ;
            </li>
            <li>
                personnaliser le contenu et mettre en œuvre vos préférences ;
            </li>
            <li>
                faire appliquer nos conditions générales d’utilisation du site et nos contrats conclus séparément avec vous (le cas échéant) ;
            </li>
            <li>
                prévenir la fraude et les autres activités illégales ou interdites ;
            </li>
            <li>
                préserver la sécurité et l'intégrité de nos sites Web, de nos entreprises, et de nos produits ou services ; ou
            </li>
            <li>
                s'acquitter d'autres fonctions ou servir à d'autres fins, tel qu'indiqué au moment de la collecte, ou lorsque la loi l'exige ou le permet.
            </li>
        </ul>
    </div>
    <div>
        <h2>
            3. Licéité du traitement
        </h2>
        <p>
            Notre base juridique en matière de collecte et d'utilisation de vos Données à caractère personnel telles que décrit dans la présente Charte de confidentialité dépendra des Données à caractère personnel concernées et du contexte spécifique dans lequel
            nous les recueillons. En général, nous recueillons et traitons vos Données à caractère personnel selon un ou plusieurs des motifs suivants :
        </p>
        <ul>
            <li>
                Votre consentement, par exemple lorsque nous avons obtenu votre consentement pour traiter vos Données à caractère personnel. Vous êtes libre de revenir à tout moment sur votre consentement en adressant un e-mail à l'adresse contacts@trapyourtrip.com.
                Le fait de revenir sur votre consentement n'aura pas d'incidence sur le caractère licite de tout traitement basé sur le consentement accordé avant de vous rétracter. Le cas échéant, nous pouvons vous demander votre consentement pour traiter
                vos Données à caractère personnel au moment de leur fourniture.
            </li>
            <li>
                Le respect d'une obligation contractuelle. Nous vous préviendrons au moment de recueillir des renseignements du caractère obligatoire ou non de fournir vos Données à caractère personnel, et des conséquences éventuelles liées au refus de nous les fournir.
            </li>
            <li>
                Le respect des obligations légales de Trap YourTrip lorsque d'autres lois exigent le traitement de vos informations personnelles (par exemple, lois en matière de santé et sécurité, de fiscalité et de blanchiment d'argent) ou lorsque vos Données à caractère
                personnel Données à caractère personnel sont requises pour protéger vos intérêts vitaux ou ceux d'une autre personne.
            </li>
            <li>
                Les intérêts légitimes de Trap YourTrip SARL (et de ses Prestataires partenaires) qui incluent la prestation du présent site Web et/ou des produits et services pertinents, et/ou la mise en œuvre d'activités de profilage et de marketing, à condition que
                les préjudices subis, tant au niveau de vos droits que de vos libertés, ne dépassent pas nos intérêts légitimes.
            </li>
        </ul>
        <p>
            Si vous avez des questions ou si vous avez besoin de plus d'informations concernant la base juridique que nous utilisons pour recueillir vos Données à caractère personnel, veuillez nous contacter à l'adresse suivante :
            <a href="">contacts@trapyourtrip.com</a>
        </p>
    </div>
    <div>
        <h2>
            4. Conservation des données à caractère personnel
        </h2>
        <p>
            Nous conserverons vos Données à caractère personnel le cas échéant conformément aux fins décrites dans la présente Charte de confidentialité, dans la mesure où votre compte est actif, et dans le respect du droit applicable et des politiques de rétention
            d’information de Trap Your Trip.
        </p>
    </div>
    <div>
        <h2>
            5. Divulgation des données à caractère personnel
        </h2>
        <p>
            Nous pouvons être amenés à partager vos Données à caractère personnel avec nos Partenaires commerciaux aux fins décrites dans la présente Charte de confidentialité, notamment afin de réaliser les transactions demandées ou de permettre à notre entreprise
            de mieux répondre à vos besoins.
        </p>
        <p>
            Nous ne partageons vos Données à caractère personnel qu'avec nos prestataires Partenaires qui acceptent de traiter et protéger vos Données à caractère personnel, et de les utiliser exclusivement aux fins énoncées par Trap your Trip Sarl. Nous pouvons
            divulguer vos Données à caractère personnel uniquement si vous nous donnez votre consentement.
        </p>
        <p>
            Nous pouvons également divulguer vos Données à caractère personnel dans des cas liés prévus par la loi, afin de lutter contre la fraude.
        </p>
        <p>
            A l’exception des cas décrits ci-dessus, nous ne divulguerons pas vos Données à caractère personnel à des tiers à des fins marketing sans votre consentement.
        </p>
    </div>
    <div>
        <h2>
            6. Accessibilité des données à caractère personnel
        </h2>
        <p>
            Trap YourTrip SARL souhaite vous aider à conserver l'exactitude de vos Données à caractère personnel. Pour consulter ou modifier vos Données à caractère personnel stockées en ligne sur votre compte, veuillez-vous reporter aux rubriques Mon Compte. Pour
            préserver la sécurité de votre compte, il vous sera demandé de vous connecter à l'aide de vos identifiant et mot de passe.
        </p>
    </div>
    <div>
        <h2>
            7. Droits de la personne concernée

        </h2>
        <p>
            Lorsque le traitement de vos Données à caractère personnel est soumis aux législations européennes sur la protection des données, vous bénéficiez des droits personnels suivants. Veuillez noter que ces droits ne sont pas absolus et que dans certains cas,
            ils sont soumis à des conditions, tel que spécifié dans la loi applicable :
        </p>
        <ul>
            <li>
                <b>Accès</b> : vous avez le droit de demander des renseignements sur la manière dont nous traitons vos Données à caractère personnel et d'obtenir une copie de ces Données à caractère personnel
            </li>
            <li>
                <b>Rectification</b> : vous avez le droit de demander la rectification de Données à caractère personnel inexactes ou incomplètes vous concernant
            </li>
            <li>
                <b>Objection</b> : vous avez le droit de vous opposer au traitement de vos Données à caractère personnel , qui est basé sur nos intérêts légitimes (tel que décrit ci-dessus)
            </li>
            <li>
                <b>Suppression</b> : vous avez le droit de demander la suppression de vos Données à caractère personnel (sous certaines conditions)
            </li>
            <li>
                <b>Prise de décision automatisée</b> : vous avez le droit de refuser la prise de décision automatisée vous concernant si cette décision produit des effets juridiques ou des incidences similaires notables vous concernant
            </li>
            <li>
                <b>Restriction</b> : vous avez le droit de nous demander de limiter notre traitement de vos Données à caractère personnel, nous n'aurons ainsi plus le droit de traiter ces informations tant que la restriction n'est pas levée
            </li>
            <li>
                <b>Transférabilité</b> : vous avez le droit de recevoir les Données à caractère personnel que vous nous avez fournies sous une forme structurée, couramment utilisée et lue par une machine, et que ces informations soient transmises à une
                autre organisation dans certaines circonstances.

            </li>
        </ul>
        <p>
            Outre ce qui précède, vous avez le droit de déposer une plainte auprès d'une autorité de régulation si vous considérez que notre traitement de vos Données à caractère personnel enfreint la loi applicable en matière de protection des données.
        </p>
        <p>
            Si vous avez des questions sur le type de Données à caractère personnel que nous détenons sur vous ou si vous souhaitez demander la suppression ou la correction de Données à caractère personnel que nous détenons sur vous, ou si vous voulez exercer tout
            autre droit personnel, veuillez adresser une demande écrite à l'adresse <a>contacts@trapyourtrip.com</a>
            <br> Nous mettrons tout en œuvre pour répondre à votre demande, mais nous nous réservons le droit de rejeter de telles demandes d'accès ou d'imposer des restrictions ou des exigences concernant de telles demandes si la loi en vigueur l'exige
            ou le permet.
        </p>
    </div>
    <div>
        <h2>
            8. Sécurité des données
        </h2>
        <p>
            Trap Your Trip met en œuvre toutes les mesures nécessaires pour protéger vos Données à caractère personnel contre les abus, les interférences et les pertes, ainsi que contre l'accès non autorisé, la modification et la divulgation. Nous procédons pour
            cela de plusieurs manières, notamment :
        </p>
        <ul>
            <li>
                en utilisant le cryptage pour la collecte ou le transfert des données sensibles, telles que les coordonnées de carte bancaires et Compte Orange money ;
            </li>
            <li>
                en mettant en place des mesures techniques et organisationnelles destinées à assurer le maintien de l'intégrité, la disponibilité et la résilience des services et systèmes de traitement ;
            </li>
            <li>
                en limitant l'accès physique à nos locaux ;
            </li>
            <li>
                en limitant l'accès aux informations que nous recueillons à votre sujet ;
            </li>
            <li>
                en faisant en sorte que nos Partenaires commerciaux et nous-mêmes disposions de mesures de sécurité adaptées pour assurer la protection de vos Données à caractère personnel; et
            </li>
            <li>
                en détruisant les Données à caractère personnel ou en les rendant anonymes dans les cas exigés par la loi.
            </li>
        </ul>
        <p>
            Nous vous recommandons de préserver la confidentialité des mots de passe que vous utilisez et de faire preuve de prudence afin d'éviter les escroqueries par « hameçonnage », lorsqu'une personne vous envoie un e-mail qui semble provenir de Trap Your Trip
            et vous invitant à fournir des informations personnelles. Trap Your Trip ne vous demandera jamais votre identifiant ou votre mot de passe par courrier électronique.
        </p>
    </div>
    <div>
        <h2>
            9. Collecte et utilisation des données à caractère personnel des enfants

        </h2>
        <p>
            Trap Your Trip prend la vie privée des enfants très au sérieux. Nous ne recueillons intentionnellement aucune information personnelle auprès des enfants par le biais de nos sites Web. Si vous êtes un mineur au sens de la loi applicable dans votre pays
            sur le recueil et traitement de Données à caractère personnel, veuillez ne pas communiquer de Données à caractère personnel par le biais de nos sites Web sans le concours et le consentement exprès d'un parent ou tuteur.
        </p>
    </div>
    <div>
        <h2>
            10. Réseaux sociaux
        </h2>
        <p>
            Notre site comprend des fonctionnalités de réseaux sociaux, notamment le bouton « J'aime » de Facebook et le bouton « Suivre » de Twitter. Ces fonctionnalités peuvent recueillir votre adresse IP, déterminer quelle page vous consultez sur notre site, et
            placer un cookie permettant d'activer correctement la fonctionnalité. Les fonctionnalités de réseaux sociaux sont hébergées soit par un tiers, soit directement sur notre site. Vos interactions avec ces fonctionnalités sont régies par la politique
            de confidentialité.
        </p>
    </div>
    <div>
        <h2>
            11. Modifications de la présente charte de confidentialité
        </h2>
        <p>
            Nous mettrons occasionnellement à jour la présente Charte de confidentialité. En cas de modification de la présente Charte de confidentialité ou de changement important concernant la manière dont nous utilisons vos Données à caractère personnel, nous
            modifierons en conséquence la présente Charte de confidentialité ainsi que la date d'entrée en vigueur de la Charte de confidentialité indiquée à la fin de cette section. Nous vous conseillons de consulter régulièrement la présente Charte
            de confidentialité pour vous tenir informé de la manière dont nous utilisons et protégeons vos Données à caractère personnel.
        </p>
    </div>
    <div>
        <h2>
            12. Nous contacter
        </h2>
        <p>
            Si vous souhaitez nous contacter pour toute question concernant nos pratiques de confidentialité, de protection et respect de votre vie privée, veuillez nous adresser un e-mail à l'adresse ou nous écrire à l'adresse suivante :
            <a href="">contacts@trapyourtrip.com</a>
        </p>
    </div>

</div>