<div class="inscription-page">
    <div class="row">
        <div class="cell-lg-12">
            <div class="formulaire">
                <div class="titre" style="line-height: 1.15; margin-bottom: 10px;">
                    Mot de passe oublié
                </div>
                <div *ngIf="!terminee" >
                    Veuillez confirmer votre adresse mail
                </div>
                <div *ngIf="terminee" >
                    {{message}}
                </div>
                <div class="text-leader2" style="margin-bottom: 20px;">

                </div>
                <form *ngIf="!terminee" [formGroup]="form" (ngSubmit)="onFormSubmit()">
                    <div class="row">
                        <div class="cell-lg-5">
                            <input formControlName="login" data-role="input" data-prepend="Email">
                        </div>
                        <div class="cell-lg-2">
                            <button class="button warning"  style="background-color: rgb(48, 164, 221);">
                                Je confirme
                            </button>
                        </div>
                    </div>
                </form>

                <div *ngIf="!terminee" class="fg-red" style="margin-top: 20px;">
                    {{message}}
                </div>
            </div>
        </div>
    </div>
</div>