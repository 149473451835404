import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hebergement-edit-old',
  templateUrl: './hebergement-edit-old.component.html',
  styleUrls: ['./hebergement-edit-old.component.scss']
})
export class HebergementEditOldComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
