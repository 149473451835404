<div class="page-sejour" style="min-height: 50vh;">
    <div class="entete2">
        <div class="row">
            <div class="cell-lg-7">
                <div class="titre">
                    {{'Mon panier' | traduction}}
                </div>
                <div class="sous-titre text-leader2">
                    {{'Vos réservations ici' | traduction}}
                </div>
            </div>
            <div class="cell-lg-5 hideOnMObile" style=" font-size: 4.0em; text-align: right; ">
                <span style="opacity: 0.7; transform: scaleX(-1); " class="mif-cart fg-white "></span>
            </div>
        </div>
    </div>
    <div class="contenu2 hideOnMObile ">
        <table style="margin-top: -3vh; padding-top: 0; " class="table table-border cell-border ">
            <thead>
                <tr>
                    <th class="hideOnMObile ">Date</th>
                    <th>{{'Date début' | traduction}}</th>
                    <th>{{'Prestation' | traduction}}</th>
                    <th>{{'Description'}}</th>
                    <th style="background-color: #fafafa; ">{{'Coût' | traduction}}</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let reservation of reservations ">
                    <td class="hideOnMObile ">{{reservation.date | date: 'yyyy-MM-dd HH:mm'}}</td>
                    <td>{{reservation.dateDebut | date: 'yyyy-MM-dd'}}</td>
                    <td>
                        <span *ngIf="reservation.sejour ">
                            {{ fuseau.toUpperCase() === 'EN' ? (reservation.sejour.titreENG ?
                            reservation.sejour.titreENG: reservation.sejour.titre) : reservation.sejour.titre}},
                        </span>
                        <span *ngIf="reservation.hebergement ">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.hebergement.titreENG ?
                            reservation.hebergement.titreENG: reservation.hebergement.titre) :
                            reservation.hebergement.titre}}

                        </span>
                        <span *ngIf="reservation.divertissement ">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissement.titreENG ?
                            reservation.divertissement.titreENG: reservation.divertissement.titre) :
                            reservation.divertissement.titre}}

                        </span>
                        <span *ngIf="reservation.divertissementItem">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissementItem.divertissement.titreENG ?
                            reservation.divertissementItem.divertissement.titreENG:
                            reservation.divertissementItem.divertissement.titre) :
                            reservation.divertissementItem.divertissement.titre}}

                            <div>
                                {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissementItem.titreENG ?
                                reservation.divertissementItem.titreENG: reservation.divertissementItem.titre) :
                                reservation.divertissementItem.titre}}

                            </div>
                        </span>
                        <span *ngIf="reservation.transport ">
                            {{reservation.transport.depart.agence.nom}}
                        </span>
                        <span *ngIf="reservation.locationVoiture ">
                            Location de voiture :
                            {{reservation.locationVoiture.voiture.categorie}}
                        </span>
                    </td>
                    <td>
                        <div *ngIf="reservation.hebergement">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.hebergement.descriptionSuccincteENG ? reservation.hebergement.descriptionSuccincteENG: reservation.hebergement.descriptionSuccincte) : reservation.hebergement.descriptionSuccincte}}
                        </div>
                        <ng-container *ngIf="reservation.transport">
                            {{description(reservation.transport.depart.trajet)}}

                            <ng-container *ngIf="reservation.transport.gare">
                                <span>
                                    : {{reservation.transport.gare.nom}}, {{reservation.transport.gare.lieu}}
                                </span>
                            </ng-container>
                            <div style="margin-top: 5px;">
                                <span *ngIf="reservation.transport.depart.vip" class="allerretour" style="font-weight: bold;">
                                    VIP
                                </span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reservation.divertissement">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissement.descriptionSuccincteENG ? reservation.divertissement.descriptionSuccincteENG: reservation.divertissement.descriptionSuccincte) : reservation.divertissement.descriptionSuccincte}}

                            <div>
                                {{'Le' | traduction}}
                                <b>{{reservation.divertissement.date | date: 'yyyy-MM-dd'}} </b>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reservation.divertissementItem">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissementItem.titreENG ? reservation.divertissementItem.titreENG: reservation.divertissementItem.titre) : reservation.divertissementItem.titre}}

                        </ng-container>
                        <div *ngIf="reservation.sejour">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.sejour.descriptionSuccincteENG ? reservation.sejour.descriptionSuccincteENG: reservation.sejour.descriptionSuccincte) : reservation.sejour.descriptionSuccincte}}

                        </div>
                        <div *ngIf="reservation.locationVoiture ">
                            {{reservation.locationVoiture.type}}
                            <span *ngIf="reservation.locationVoiture.type==='interurbain' ">
                                : {{reservation.locationVoiture.depart}} - {{reservation.locationVoiture.arrivee}}
                            </span>
                            <span *ngIf="reservation.locationVoiture.type==='location' ">
                                : {{reservation.locationVoiture.ville}}
                            </span>
                            <div *ngIf="reservation.locationVoiture ">
                                Du {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}} à {{toDate(reservation.dateDebut) | date: 'HH:mm'}} au {{toDate(reservation.dateFin) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}} à {{toDate(reservation.dateFin) | date: 'HH:mm'}}
                            </div>
                        </div>
                    </td>
                    <td style="text-align: right; background-color: #fafafa; ">
                        <b>
                            {{reservation.cout | conversion | currency:devise:'symbol':'1.2-2':fuseau}}
                        </b>
                    </td>
                    <td class="white-space">
                        <button (click)="voir(reservation) " class="button ">
                            <span class="mif-eye "></span>
                        </button> &nbsp;
                        <button (click)="modifier(reservation) " class="button ">
                            <span class="mif-pencil "></span>
                        </button> &nbsp;
                        <button (click)="supprimer(reservation.id) " class="button alert danger ">
                            <span class="mif-bin "></span>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th style="font-size: 1.2em; " colspan="4 ">Total</th>
                    <th style="font-size: 1.2em; text-align: right;background-color: #fafafa; ">
                        {{TOTAL | conversion | currency:devise:'symbol':'1.2-2':fuseau}}
                    </th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
        <div *ngIf="reservations && reservations.length> 0" style="margin-top: 20px;">

            <div class="row">
                <div class="col-lg-4">
                    <a (click)="supprimerTout()" class="button alert danger" style="color: white;">
                        {{'Annuler toutes les réservations' | traduction}}
                    </a>
                </div>
                <div class="col-lg-8">
                    <form #formMobile method="post" action="https://trapyourtrip.com/cinetpay-sdk-php/action.php">

                        <input type="hidden" name="amount" [value]="TOTAL">
                        <!-- <input type="hidden" name="amount" [value]="100"> -->
                        <input type="hidden" name="currency" value="XAF">
                        <input type="hidden" name="valider" [value]="true">
                        <input *ngIf="responsable" type="hidden" name="customer_name" [value]="responsable.nom">
                        <input *ngIf="responsable" type="hidden" name="customer_surname" [value]="responsable.prenom">
                        <input type="hidden" name="description" value="Achat sur Trap your trip">
                        <input type="hidden" name="id" [value]="paiement.id">
                        <input type="hidden" name="returnURL" [value]="returnURL">
                        <input type="hidden" name="cancelURL" [value]="cancelURL">
                        <input type="hidden" name="notifyURL" [value]="notifyURL">

                        <a (click)="payerMobile(formMobile)" class="button success" style="color: white; float: right; ">
                            {{'Paiement mobile' | traduction}}
                            <span style="margin-left: 10px;" class="fas fa-mobile fg-white"></span>
                        </a>
                    </form>
                    <form #formBancaire [action]="lienStripe" method="post">

                        <input type="hidden" name="montant" [value]="TOTAL | conversion">
                        <input type="hidden" name="email" [value]="reservations ? reservations[0].responsable.email : ''">
                        <input type="hidden" name="currency" [value]="devise">

                        <button type="submit" (click)="payerBancaire(formBancaire)" class="button success" style="background-color: rgb(48, 164, 221); color: white; float: right; margin-right: 10px; ">

                            {{'Paiement par carte' | traduction}}
                            <span style="margin-left: 10px;" class="fas fa-credit-card fg-white"></span>
                        </button>
                    </form>
                    <!-- <button *ngIf="!isUser " class="button warning " style="background-color: rgb(48, 164, 221); float: right; " (click)="suivant() ">
                        {{'Suivant' | traduction}}
                    </button> -->
                </div>
            </div>
        </div>
    </div>
    <div class="contenu2 showOnMObile " style="width: 100vw; overflow-x: hidden; ">
        <table style="margin-top: 0; padding-top: 0; " class="table table-border cell-border ">
            <thead>
                <tr>
                    <th>{{'Prestations' | traduction}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let reservation of reservations ">
                    <td>
                        <b>Date</b> : {{reservation.date | date: 'yyyy-MM-dd HH:mm'}}<br>
                        <b>{{'Date début' | traduction}}</b> : {{reservation.dateDebut | date: 'yyyy-MM-dd'}}<br>
                        <b>{{'Prestation' | traduction}}</b> :
                        <span *ngIf="reservation.sejour ">
                            {{ fuseau.toUpperCase() === 'EN' ? (reservation.sejour.titreENG ?
                            reservation.sejour.titreENG: reservation.sejour.titre) : reservation.sejour.titre}},
                        </span>
                        <span *ngIf="reservation.hebergement ">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.hebergement.titreENG ?
                            reservation.hebergement.titreENG: reservation.hebergement.titre) :
                            reservation.hebergement.titre}}

                        </span>
                        <span *ngIf="reservation.divertissement ">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissement.titreENG ?
                            reservation.divertissement.titreENG: reservation.divertissement.titre) :
                            reservation.divertissement.titre}}

                        </span>
                        <span *ngIf="reservation.divertissementItem">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissementItem.divertissement.titreENG ?
                            reservation.divertissementItem.divertissement.titreENG:
                            reservation.divertissementItem.divertissement.titre) :
                            reservation.divertissementItem.divertissement.titre}}

                            <div>
                                {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissementItem.titreENG ?
                                reservation.divertissementItem.titreENG: reservation.divertissementItem.titre) :
                                reservation.divertissementItem.titre}}

                            </div>
                        </span>
                        <span *ngIf="reservation.transport ">
                            {{reservation.transport.depart.agence.nom}}
                        </span>
                        <span *ngIf="reservation.locationVoiture ">
                            {{'Location de voiture' | traduction}} :
                            {{reservation.locationVoiture.voiture.categorie}}
                        </span>
                        <br>
                        <b>Description</b> :

                        <div *ngIf="reservation.hebergement">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.hebergement.descriptionSuccincteENG ? reservation.hebergement.descriptionSuccincteENG: reservation.hebergement.descriptionSuccincte) : reservation.hebergement.descriptionSuccincte}}
                        </div>
                        <ng-container *ngIf="reservation.transport">
                            {{description(reservation.transport.depart.trajet)}}

                            <ng-container *ngIf="reservation.transport.gare">
                                <span>
                                    : {{reservation.transport.gare.nom}}, {{reservation.transport.gare.lieu}}
                                </span>
                            </ng-container>
                            <div style="margin-top: 5px;">
                                <span *ngIf="reservation.transport.depart.vip" class="allerretour" style="font-weight: bold;">
                                    VIP
                                </span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reservation.divertissement">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissement.descriptionSuccincteENG ? reservation.divertissement.descriptionSuccincteENG: reservation.divertissement.descriptionSuccincte) : reservation.divertissement.descriptionSuccincte}}

                            <div>
                                {{'Le' | traduction}}
                                <b>{{reservation.divertissement.date | date: 'yyyy-MM-dd'}} </b>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reservation.divertissementItem">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissementItem.titreENG ? reservation.divertissementItem.titreENG: reservation.divertissementItem.titre) : reservation.divertissementItem.titre}}

                        </ng-container>
                        <div *ngIf="reservation.sejour">
                            {{fuseau.toUpperCase() === 'EN' ? (reservation.sejour.descriptionSuccincteENG ? reservation.sejour.descriptionSuccincteENG: reservation.sejour.descriptionSuccincte) : reservation.sejour.descriptionSuccincte}}

                        </div>
                        <div *ngIf="reservation.locationVoiture ">
                            {{reservation.locationVoiture.type}}
                            <span *ngIf="reservation.locationVoiture.type==='interurbain' ">
                                : {{reservation.locationVoiture.depart}} - {{reservation.locationVoiture.arrivee}}
                            </span>
                            <span *ngIf="reservation.locationVoiture.type==='location' ">
                                : {{reservation.locationVoiture.ville}}
                            </span>
                            <div *ngIf="reservation.locationVoiture ">
                                {{'Du' | traduction}} {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}} {{'à' | traduction}} {{toDate(reservation.dateDebut) | date: 'HH:mm'}} {{'au' | traduction}} {{toDate(reservation.dateFin) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}}
                                à {{toDate(reservation.dateFin) | date: 'HH:mm'}}
                            </div>
                        </div>
                        <br>
                        <b>{{'Coût' | traduction}}</b> : {{reservation.cout | conversion | currency:devise:'symbol':'1.2-2':fuseau}}
                        <br>

                        <div class="white-space " style="margin-top: 10px; margin-bottom: 10px; ">
                            <button (click)="voir(reservation) " class="button success ">
                                {{'Voir' | traduction}}
                            </button>
                            <button (click)="modifier(reservation) " class="button warning " style="background-color: rgb(48, 164, 221); ">
                                {{'Modifier' | traduction}}
                            </button>
                            <button (click)="supprimer(reservation.id) " class="button alert danger ">
                                {{'Supprimer' | traduction}}
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th style="font-size: 1.2em; text-align: right;background-color: #fafafa; ">
                        Total : {{TOTAL | conversion | currency:devise:'symbol':'1.2-2':fuseau}}
                    </th>
                </tr>
            </tfoot>
        </table>
        <div *ngIf="reservations && reservations.length> 0" style="margin-top: 20px;">
            <div>
                <div style="margin-bottom: 10px;">
                    <a (click)="supprimerTout()" class="button alert danger" style="color: white; width: 100%;">
                        {{'Annuler toutes les réservations' | traduction}}
                    </a>
                </div>
                <div style="margin-bottom: 10px;">
                    <form #formMobile method="post" action="https://trapyourtrip.com/cinetpay-sdk-php/action.php">

                        <input type="hidden" name="amount" [value]="TOTAL">
                        <!-- <input type="hidden" name="amount" [value]="100"> -->
                        <input type="hidden" name="currency" value="XAF">
                        <input type="hidden" name="valider" [value]="true">
                        <input *ngIf="responsable" type="hidden" name="customer_name" [value]="responsable.nom">
                        <input *ngIf="responsable" type="hidden" name="customer_surname" [value]="responsable.prenom">
                        <input type="hidden" name="description" value="Achat sur Trap your trip">
                        <input type="hidden" name="id" [value]="paiement.id">
                        <input type="hidden" name="returnURL" [value]="returnURL">
                        <input type="hidden" name="cancelURL" [value]="cancelURL">
                        <input type="hidden" name="notifyURL" [value]="notifyURL">

                        <a (click)="payerMobile(formMobile)" class="button success" style="color: white; width: 100%; ">
                            {{'Paiement mobile' | traduction}}
                            <span style="margin-left: 10px;" class="fas fa-mobile fg-white"></span>
                        </a>
                    </form>
                </div>
                <div style="margin-bottom: 10px;">
                    <form #formBancaire2 [action]="lienStripe" method="post">

                        <input type="hidden" name="montant" [value]="TOTAL | conversion">
                        <input type="hidden" name="email" [value]="reservations ? reservations[0].responsable.email : ''">
                        <input type="hidden" name="currency" [value]="devise">


                        <button (click)="payerBancaire(formBancaire2)" class="button success" style="background-color: rgb(48, 164, 221); color: white; margin-right: 10px;width: 100%; ">

                            {{'Paiement par carte' | traduction}}
                            <span style="margin-left: 10px;" class="fas fa-credit-card fg-white"></span>
                        </button>
                    </form>
                </div>
                <div style="margin-bottom: 10px;">
                    <!-- <button *ngIf="!isUser" class="button warning suite" style="background-color: rgb(48, 164, 221);" (click)="suivant()">
                        {{'Suivant' | traduction}}
                    </button> -->
                </div>
            </div>
        </div>
    </div>
</div>