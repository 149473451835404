import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agence-view',
  templateUrl: './agence-view.component.html',
  styleUrls: ['./agence-view.component.scss']
})
export class AgenceViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
