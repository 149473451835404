import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loisir-edit',
  templateUrl: './loisir-edit.component.html',
  styleUrls: ['./loisir-edit.component.scss']
})
export class LoisirEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
