<div class="offres">
    <div class="muanza-mobile-rub2">
        <div class="row">
            <div class="cell-lg-3">
                <div class="offre" (click)="goTo('offres/sejour')">
                    <span class="libelle">
                        {{'Séjours' | traduction}}
                    </span>
                    <span class="icone">
                        <span class="mif-location-city"></span>
                    </span>
                </div>
            </div>
            <div class="cell-lg-3">
                <div class="offre" (click)="goTo('offres/hebergement')">
                    <span class="libelle">
                        {{'Hébergements' | traduction}}
                    </span>
                    <span class="icone">
                        <span class="mif-hotel"></span>
                    </span>
                </div>
            </div>
            <div class="cell-lg-3">
                <div class="offre" (click)="goTo('offres/transport')">
                    <span class="libelle">
                        {{'Transports' | traduction}}
                    </span>
                    <span class="icone">
                        <span class="mif-bus"></span>
                    </span>
                </div>
            </div>
            <div class="cell-lg-3">
                <div class="offre" (click)="goTo('offres/divertissement')">
                    <span class="libelle">
                        {{'Divertissements' | traduction}}
                    </span>
                    <span class="icone">
                        <span class="mif-dribbble"></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>