<div class="page">
    <div class="entete">
        <div *ngIf="!agence" class="titre">
            Ajoutez une nouvelle agence
        </div>
        <div *ngIf="agence" class="titre">
            Modifier l'agence : {{agence.nom}}
        </div>
    </div>
    <div class="contenu">
        <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
            <div>
                <input formControlName="nom" data-role="input" data-prepend="Nom de la compagnie">
            </div>
            <div *ngIf="!agence">
                <input formControlName="bus" data-role="checkbox" data-caption="Agence de voyage">
            </div>
            <div *ngIf="agence">
                <input formControlName="bus" type="checkbox" data-role="checkbox" data-caption="Agence de voyage">
            </div>
            <div>
                <button class="button">Enregistrer</button>
            </div>
        </form>
    </div>
</div>