<div class="titre">
    Nouveau transport
</div>
<div class="text-leader2" style="margin-bottom: 20px;">
    Création d'une nouvelle offre de transport
</div>
<div class="formulaire">
    <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
        <div>
            <input data-role="input" formControlName="titre" data-prepend="Titre">
        </div>
        <div>
            <input data-role="input" type="number" formControlName="prixUnitaire" data-prepend="Prix Unitaire">
        </div>
        <div>
            <textarea data-role="textarea" formControlName="description" data-prepend="Description"></textarea>
        </div>
        <div>
            <input data-role="input" type="number" formControlName="notation" data-prepend="Notation">
        </div>
        <div>
            <input (change)="uploadFile($event)" data-role="file" type="file" multiple data-prepend="Images">
        </div>
        <div class="row">
            <div class="cell-lg-6">
                <button class="button warning">Enregistrer</button>
            </div>
        </div>
    </form>
</div>