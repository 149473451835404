<div style="min-height: 60vh; background-color: #111;">

    <div class="rubriques fg-white">
        <div class="row">
            <div class="cell-lg-3 cell-7">
                <div class="rubrique">
                    <b style="color: rgb(48, 164, 221);">
                        TRAP YOUR TRIP
                    </b>
                    <div style="line-height: 1.25; width: 90%;">
                        {{'Une solution développée par' | traduction}}
                        <br> SMART BLUE TECHNOLOGY SARL
                    </div>
                </div>
                <div class="rubrique showOnMObile">
                    <div style="margin-top: 20px;">
                        {{"Réservez des hôtels et appartements au Cameroun (Yaoundé, Douala, Kribi,…) , ainsi que des tickets de transport." | traduction }}
                        <div>
                            {{"Louez des voitures et réservez de nombreux divertissements" | traduction }}
                        </div>

                    </div>
                    <div>
                        Yaoundé, {{'Cameroun' | traduction}}
                    </div>
                    <div>
                        Tel : +237 699 18 03 47
                    </div>
                    <div>
                        Tel : +33 645136438
                    </div>
                    <div>
                        Email : contacts@trapyourtrip.com
                    </div>
                </div>
            </div>
            <div class="cell-lg-2 cell-5">
                <div class="rubrique">
                    <div style="padding-bottom: 10px;">
                        <b>
                            A VOTRE ECOUTE
                        </b>
                        <div style="color: #aaa;">
                            <a routerLink="/about" style="color: rgb(48, 164, 221);">
                                {{'Qui sommes-nous ?' | traduction}}
                            </a>
                        </div>
                        <div style="color: #aaa;">
                            <a routerLink="/conditions" style="color: rgb(48, 164, 221);">
                                {{'Conditions générales d’utilisation' | traduction}}
                            </a>
                        </div>
                        <div style="color: #aaa;">
                            <a routerLink="/charte" style="color: rgb(48, 164, 221);">
                                {{'Charte de confidentialité' | traduction}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cell-lg-4 cell-6">
                <div class="rubrique hideOnMObile">
                    <div style="margin-bottom: 20px;">
                        {{"Réservez des hôtels et appartements au Cameroun (Yaoundé, Douala, Kribi,…) , ainsi que des tickets de transport." | traduction }}
                        <div>
                            {{"Louez des voitures et réservez de nombreux divertissements" | traduction }}
                        </div>
                    </div>
                    <div>
                        Yaoundé, {{'Cameroun' | traduction}}
                    </div>
                    <div>
                        Tel : +237 699 18 03 47
                    </div>
                    <div>
                        Tel : +33 645136438
                    </div>
                    <div>
                        Email : contacts@trapyourtrip.com
                    </div>
                </div>
            </div>
            <div class="cell-lg-3 cell-12">
                <div class="rubrique">
                    <b style="color: rgb(48, 164, 221);">
                        {{'RESEAUX SOCIAUX' | traduction}}
                    </b>
                    <div class="rs" (click)="goToFacebook()">
                        Facebook
                    </div>
                    <div class="rs" (click)="goToInstagram()">
                        Instagram
                    </div>
                    <div>
                        Twitter
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>