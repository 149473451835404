<div class="page-sejour">
    <div class="entete" style="background-color: #eee;">

        <div class="entete__background">
        </div>
        <div class="entete__contenu">
            <div style="margin-left: -15px; margin-bottom: -20px;">
                <ul class="breadcrumbs" style="background-color: rgba(0,0,0,0);">
                    <li class="page-item"><a routerLink="accueil" class="page-link">{{'Accueil' | traduction}}</a></li>
                    <li class="page-item"><a (click)="goToAll()" class="page-link">{{'Divertissements' | traduction}}</a></li>
                    <li class="page-item"><a class="page-link">
                        {{'Evènements' | traduction}}
                    </a></li>
                </ul>
            </div>
            <div class="row no-gap">
                <div class="cell-lg-9">
                    <div class="titre">
                        {{'Evènements' | traduction}}
                    </div>
                    <div class="sous-titre">
                        <div>
                            {{'Tous les événements' | traduction}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contenu">

        <form class="formulaire" [formGroup]="form" (ngSubmit)="onFormSubmit()">
            <div class="row no-gap shadow-3">

                <div class="cell-lg-3 bordure-orange-debut">
                    <input formControlName="rechercher" [placeholder]="'Rechercher' | traduction" data-role="input">
                </div>
                <div class="cell-lg-2 bordure-orange-fin">
                    <div *ngIf="villes.length === 0">
                        <input #ville [attr.data-prepend]="'Ville' | traduction" formControlName="ville" data-role="input" data-clear-button="false" data-autocomplete="Douala, Yaoundé, Kribi, Limbé, Garoua, Maroua">
                    </div>
                    <div *ngIf="villes.length > 0">
                        <select formControlName="ville" [attr.data-prepend]="'Ville' | traduction" data-role="select">
                            <option value="">{{'Toutes les villes' | traduction}}</option>
                            <option *ngFor="let v of villes" [value]="v.nom">
                                {{v.nom}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="cell-lg-3 bordure-orange-fin" style="">
                    <input #calendarpickerlocale formControlName="dateDebut" data-role="calendarpicker" [attr.data-prepend]="'A partir du' | traduction">
                </div>
                <div class="cell-lg-3 bordure-orange-fin">
                    <input #calendarpickerlocale2 formControlName="dateFin" data-role="calendarpicker" [attr.data-prepend]="'Avant le' | traduction">
                </div>
                <div class="cell-lg-1 bordure-orange-fin">
                    <button class="button warning" style="width: 100%; background-color: rgb(48, 164, 221);">
                        {{'Rechercher' | traduction}}
                    </button>
                </div>
            </div>
        </form>
        <div class="sejours">
            <div class="row">
                <div class="cell-lg-4" *ngFor="let divertissement of resultats">
                    <app-display-divertissement [divertissement]="divertissement"></app-display-divertissement>
                </div>
            </div>
        </div>
    </div>
</div>