<div class="page" style="min-height: 100vh; padding-top: 10px;">
    <div style="margin-top: 0px; margin-bottom: 20px;">
        <div data-role="stepper" data-steps="3" data-step="1" data-view="diamond" data-cls-step="rounded" data-cls-complete="bg-green" data-cls-current="bg-blue">
        </div>
    </div>
    <div class="entete" *ngIf="reservation">
        <app-display-reservation-description [reservation]="reservation"></app-display-reservation-description>

        <div style="margin-top: 40px">
            <button (click)="infos(reservation)" class="button warning" style="background-color:  rgb(48, 164, 221); color: #fff;">
                {{'Etape suivante' | traduction}}
            </button>
        </div>

    </div>
    <div class="contenu">

    </div>
</div>

<div *ngIf="showconnexionObligatoire" class="connexion-obligatoire">
    <div class="connexion-obligatoire-logo">
        <img class="image-logo" src="../../../assets/img/Trap Your Trip Logo 2.png">
    </div>
    <div class="connexion-obligatoire-timer">
        0{{seconds}}:00
    </div>
    <div class="connexion-obligatoire-titre">
        {{'Veuillez-vous connecter' | traduction}} !
    </div>
    <div class="connexion-obligatoire-contenu">
        {{'Vous devez vous connecter pour effectuer une réservation.' | traduction}} {{'Vous serez redirigé momentanément vers la page de connexion' | traduction}}
    </div>
    <div class="connexion-obligatoire-compris">
        <button (click)="okJaiCompris(reservation)" class="button fg-white bg-blue">Ok ! J'ai compris</button>
    </div>
</div>