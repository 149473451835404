<div style="min-height: 100vh; padding-top: 10px;">
    <div class="entete2">
        <div class="titre">
            Mes réservations
        </div>
        <div *ngIf="utilisateur" class="sous-titre">
            <ng-container *ngIf="!utilisateur.prestataire">
                Retrouvez toutes les réservations que vous avez effectuées
            </ng-container>
            <ng-container *ngIf="utilisateur.prestataire">
                Retrouvez toutes les réservations effectuées par des clients sur votre établissement
            </ng-container>

        </div>
    </div>
    <div class="contenu">
        <div>
            <table class="table table-border cell-border">
                <thead>
                    <tr>
                        <th>Hébergement</th>
                        <th>Début</th>
                        <th>Fin</th>
                        <th style="text-align: right; background-color: #fafafa;" s>Coût</th>
                        <th>Statut</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let reservation of reservations">
                        <td style="line-height: 1.15;">
                            <b>
                                {{setTitre(reservation)}}
                            </b>
                            <div>
                                <small>
                                    {{setDescription(reservation)}}
                                </small>
                            </div>
                        </td>
                        <td>{{reservation.date | date: 'yyyy-MM-dd HH:mm'}}</td>
                        <td>{{reservation.dateDebut | date: 'yyyy-MM-dd'}}</td>
                        <td style="text-align: right; background-color: #fafafa;">
                            <b>
                                {{reservation.cout  | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                            </b>
                        </td>
                        <td>
                            {{reservation.validee ? 'Validéé': 'En attente de validation'}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>