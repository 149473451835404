<p *ngIf="prestataire && !prestataire.prestataire">
    Erreur de prestataire
</p>
<div *ngIf="prestataire && prestataire.prestataire" [ngClass]="{'indisponible': prestataire.indisponible}" class="sejour" (click)="ouvrir(prestataire.id)">
    <div class="sejour__conteneur">
        <div class="sejour__image" [ngStyle]="{'background-image': 'url(' + photoURL +')'}">
        </div>
        <div *ngIf="prestataire.options" class="muanza-details">
            <span title="Piscine" *ngIf="prestataire.options.piscine" class="detail">
                <span class="fas fa-swimmer fg-white"></span>
            </span>
            <span title="Plage" *ngIf="prestataire.options.plage" class="detail">
                <span class="fas fa-umbrella-beach fg-white"></span>
            </span>
            <span title="Spa" *ngIf="prestataire.options.spa" class="detail">
                <span class="fg-white fas fa-hot-tub"></span>
            </span>
            <span title="Petit déjeuner" *ngIf="prestataire.options.petitdej" class="detail">
                <span class="fg-white fas fa-mug-hot"></span>
            </span>
            <span title="Petit déjeuner et déjeuner compris" *ngIf="prestataire.options.dej" class="detail">
                <span class="fg-white fas fa-utensils"></span>
            </span>
            <span title="Logement avec cuisine" *ngIf="prestataire.options.cuisine" class="detail">
                <span class="fg-white fas fa-dumpster-fire"></span>
            </span>
        </div>
    </div>
    <div *ngIf="prestataire.indisponible" class="sejour__description">
        <b>
            {{prestataire.nom}}
        </b>
        <p style="line-height: 1.15;">
            Ce prestataire n'accepte pas de réservation pour le moment
        </p>
    </div>
    <div *ngIf="!prestataire.indisponible" class="sejour__description">
        <div style="line-height: 1.2;">
            <div>
                <b class="petit-titre">
                    {{prestataire.nom}}
                </b>
            </div>
            <div>
                <span class="sejour__notation orange" [innerHTML]="notationToStars(prestataire.notation)"></span>

            </div>
        </div>
        <div style="margin-top: 0px; width: 60%; line-height: 1.1; font-size: 0.9em; opacity: 0.75;">
            <ng-container *ngIf="prestataire.pays">{{prestataire.pays}}</ng-container>
            <ng-container *ngIf="prestataire.ville && prestataire.pays">,</ng-container>

            <ng-container *ngIf="prestataire.ville">
                {{prestataire.ville}}
            </ng-container>

            <span *ngIf="prestataire.localisation">
                <ng-container *ngIf="prestataire.pays || prestataire.ville"> - </ng-container>
                <span>
                    {{prestataire.localisation}}
                </span>
            </span>
        </div>
        <div class="prix" *ngIf="prestataire.prixMin && prestataire.prixMin > 0">
            <span>
                {{'A partir de' | traduction}} {{prestataire.prixMin | conversion  | currency:devise:'symbol':'1.2-2':fuseau}}
            </span>
        </div>
        <div class="nature">
            <ng-container *ngIf="prestataire.hotel">
                {{'Hôtel' | traduction}}
            </ng-container>

            <ng-container *ngIf="prestataire.villa">
                {{'Appartement' | traduction}}
            </ng-container>

            <ng-container *ngIf="prestataire.lodge">
                {{'Lodge' | traduction}}
            </ng-container>

        </div>
    </div>
</div>