<div class="trait">

</div>
<div class="titre">
    <span>
        {{"Nos offres d'hébergement" | traduction}}
    </span>
</div>

<div class="sejours">
    <div class="row">
        <div class="cell-lg-3">
            <div class="description text-leader2">
                {{"Découvrez toutes nos offres d'hébergement pour un confort sans pareil" | traduction}}
            </div>

        </div>
        <div class="cell-lg-9">
            <div class="row">
                <div class="cell-lg-4" *ngFor="let prestataire of utilisateurs; let index = index;">
                    <div *ngIf="index < 6">
                        <app-display-prestataire [prestataire]="prestataire"></app-display-prestataire>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>