<div class="image-arriere">
    <div class="hideOnMObile" data-role="carousel" data-height="400" data-period="5000" data-duration="1000" data-auto-start="true" data-bullets="false" data-controls="false">

        <div class="slide" data-cover="../../../../assets/img/arriereplan1.jpg"></div>
        <div class="slide" data-cover="../../../../assets/img/arriereplan3.jpg"></div>
        <div class="slide" data-cover="../../../../assets/img/restaurants.jpg"></div>

    </div>
</div>
<div class="filtre">
</div>
<div class="cadre">
    <div class="row no-gap">
        <div class="cell-lg-8">
            <div class="recherche">
                <div class="carousel-titre">
                    {{'Rechercher une destination' | traduction}}
                </div>
                <div class="complement">
                    {{"Veuillez saisir le nom d'une ville, afin d'y décourvrir toutes nos offres" | traduction}}
                </div>
                <input *ngIf="mobile" [(ngModel)]="recherche" (ngModelChange)="rechercher($event)" data-role="materialinput" placeholder="Entrez le nom d'une ville">
                <input *ngIf="!mobile" [(ngModel)]="recherche" (ngModelChange)="rechercher($event)" class="trap-input hideOnMObile shadow-3">
                <div class="resultats">
                    <small class="showOnMObile propositions-titre">
                        Quelques propositions
                    </small>
                    <ng-container *ngFor="let v of villesResultats; let i = index">
                        <div *ngIf="i < nombreVilles" (click)="ouvrir(v)" class="resultat">
                            <span class="photo">
                                <img src="../../../../assets/img/villa1.jpg">
                            </span>
                            <span class="details" style="text-transform: capitalize;">
                                <span class="nom">{{v.nom}}</span>
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="cell-lg-4">
            <div class="icone-recherche hideOnMObile">
                <i class="mif-search"></i>
            </div>
        </div>
    </div>
</div>