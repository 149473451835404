<div class="trait">

</div>
<div class="titre">
    <span>
        {{"Nos offres de transports" | traduction}}
    </span>
</div>

<div class="transports">
    <div class="row">
        <div class="cell-lg-4" *ngFor="let v of voitures; let index = index;">
            <div *ngIf="index < 6">
                <app-display-voiture [voiture]="v"></app-display-voiture>
            </div>
        </div>
    </div>
</div>