<div>
    <div style="padding: 10%; padding-top: 7%; padding-bottom: 5vmin;">
        <h1>
            A propos de Trap Your Trip
        </h1>
        <p>
            La plateforme de réservation Trap Your Trip est une solution digitale développé par la société SMART BLUE TECHNOLOGY SARL au Cameroun. A travers la solution digitale de réservation de séjours touristiques (Hôtels, Maison d’Hôtes, Tickets de transports,
            Billetterie) SMART BLUE TECHNOLOGY ambitionne devenir l’une des plus grandes sociétés de voyage en Afrique et dans le monde.
        </p>
        <p>
            Pour mener à bien cette mission et permettre à chacun de vivre des expériences en toute simplicité, la société investit dans la technologie. La plateforme de réservation en ligne TrapYourTrip.com souhaite proposer ainsi à des millions de voyageurs des
            activités inoubliables, des options de transport interurbain optimales et des hébergements incroyables (appartements, hôtels, et bien plus encore).
        </p>
        <p>
            TrapYourTrip.com se veut être l’une des plus grandes plateformes de voyage en Afrique et dans le monde et ambitionne de servir tant les entreprises que les particuliers. Elle permet aux établissements de toutes tailles présents en Afrique d’atteindre
            une clientèle nationale et internationale. Elle permet enfin à ces entreprises de développer leur activité.
        </p>
        <p>
            La plateforme de réservation Trap Your Trip vous garantit une assistance 24h/24 et 7j/7 aux utilisateurs comme aux prestataires partenaires
        </p>
        <p>
            Pour mener à bien cette mission et permettre à chacun de vivre des expériences en toute simplicité, la société investit dans la technologie. TrapYourTrip.com souhaite proposer ainsi à des millions de voyageurs des activités inoubliables, des options de
            transport interurbain optimales et des hébergements incroyables (appartements, hôtels, et bien plus encore). TrapYourTrip.com se veut être l’une des plus grandes plateformes de voyage en Afrique et dans le monde et ambitionne de servir tant
            les entreprises que les particuliers. Elle permet aux établissements de toutes tailles présents en Afrique d’atteindre une clientèle nationale et internationale. Elle permet enfin à ces entreprises de développer leur activité.
        </p>
        <p>
            Trap Your Trip vous garantit une assistance 24h/24 et 7j/7 aux utilisateurs comme aux prestataires partenaires.
        </p>


        <div>
            <h2>
                Nom et Adresse :
            </h2>
            <div>
                SMART BLUE TECHNOLOGY SARL Santa Barbara Yaoundé Cameroun
            </div>
        </div>
        <div>
            <h2>
                Coordonnées :
            </h2>
            <div>
                + 33 6 45 13 64 38 +237 6 99 18 03 47
            </div>
        </div>

        <div>
            <h2>
                Société immatriculée au registre de :
            </h2>
            <div>
                YAOUNDE sous le numéro d’immatriculation RC/YAE/2022/B/3021
            </div>
        </div>
    </div>
    <div style="padding: 10%; padding-top: 0vmin;">
        <h1>
            Que propose la plateforme de réservation TrapYourTrip.com ?
        </h1>
        <h2>
            Des hébergements incroyables
        </h2>
        <p>
            Que vous souhaitiez séjourner dans un appartement design en plein centre-ville, un complexe hôtelier de luxe en bord de mer ou de cosy chambres d'hôtes, TrapYourTrip.com vous propose de nombreux types d'hébergements !
        </p>
        <h2>
            Tarifs bas
        </h2>
        <p>
            TrapYourTrip.com garantit de vous proposer les meilleurs tarifs disponibles. Et si vous trouvez moins cher ailleurs ? Pas de problème, nous ajustons nos tarifs ! Vous êtes ainsi assuré(e) de toujours bénéficier d'une super offre.
        </p>
        <h2>
            Pas de frais de réservation
        </h2>
        <p>
            Nous ne facturons aucun frais de réservation et n'ajoutons aucun frais administratif. Dans la plupart des cas, votre réservation peut également être annulée gratuitement.*
        </p>
        <h2>
            Réservation sécurisée
        </h2>
        <p>
            Notre plateforme sécurisée et nos agents expérimentés applique et observe les normes les plus rigoureuses pour protéger la confidentialité de vos informations personnelles. Pour plus de renseignements, veuillez consulter notre
            <b>
                Charte de confidentialité.
            </b>
        </p>
        <h2>
            Assistance disponible 24h/24 et 7j/7
        </h2>
        <p>
            Que vous veniez de réserver ou que vous soyez déjà sur le lieu de vos vacances, notre Service Clients est à votre entière disposition, de jour comme de nuit, pour répondre à vos questions et vous assister. Profitez-en pour consulter notre <b>FAQ</b>            et lire les questions les plus fréquentes posées par les voyageurs.
        </p>
        <div>
            *Si l’annulation n’est pas effectuée tardivement voir les conditions d’annulation propre à chaque établissement.
        </div>
    </div>
</div>