<div class="row">
    <div class="cell-lg-9">
        <div class="titre2">

            <ng-container *ngIf="reservation.hebergement">
                <b *ngIf="reservation.hebergement.prestataire">
                    {{reservation.hebergement.prestataire.nom}} -
                </b> {{fuseau.toUpperCase() === 'EN' ? (reservation.hebergement.titreENG ? reservation.hebergement.titreENG: reservation.hebergement.titre) : reservation.hebergement.titre}}

                <span class="sejour__notation fg-bleu" [innerHTML]="notationToStars(reservation.hebergement.notation)"></span>
            </ng-container>
            <ng-container *ngIf="reservation.transport">
                {{reservation.transport.depart.agence.nom}} :
                <b>
                    {{reservation.transport.depart.modele}}
                </b>
            </ng-container>

            <ng-container *ngIf="reservation.divertissement">
                {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissement.titreENG ? reservation.divertissement.titreENG: reservation.divertissement.titre) : reservation.divertissement.titre}}

            </ng-container>
            <ng-container *ngIf="reservation.divertissementItem">
                {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissementItem.divertissement.titreENG ? reservation.divertissementItem.divertissement.titreENG: reservation.divertissementItem.divertissement.titre) : reservation.divertissementItem.divertissement.titre}}
            </ng-container>
            <ng-container *ngIf="reservation.sejour">
                {{ fuseau.toUpperCase() === 'EN' ? (reservation.sejour.titreENG ? reservation.sejour.titreENG: reservation.sejour.titre) : reservation.sejour.titre}},
            </ng-container>
            <ng-container *ngIf="reservation.locationVoiture">
                {{'Location de voiture' | traduction}} : {{reservation.locationVoiture.voiture.categorie}}, {{reservation.locationVoiture.voiture.modele}}
            </ng-container>
        </div>

        <div class="sous-titre text-leader2">
            <div *ngIf="reservation.hebergement">
                {{fuseau.toUpperCase() === 'EN' ? (reservation.hebergement.descriptionSuccincteENG ? reservation.hebergement.descriptionSuccincteENG: reservation.hebergement.descriptionSuccincte) : reservation.hebergement.descriptionSuccincte}}
            </div>
            <ng-container *ngIf="reservation.transport">
                {{description(reservation.transport.depart.trajet)}}

                <ng-container *ngIf="reservation.transport.gare">
                    <span>
                        : {{reservation.transport.gare.nom}}, {{reservation.transport.gare.lieu}}
                    </span>
                </ng-container>
                <div style="margin-top: 5px;">
                    <span *ngIf="reservation.transport.depart.vip" class="allerretour" style="font-weight: bold;">
                        VIP
                    </span>
                </div>
            </ng-container>
            <ng-container *ngIf="reservation.divertissement">
                {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissement.descriptionSuccincteENG ? reservation.divertissement.descriptionSuccincteENG: reservation.divertissement.descriptionSuccincte) : reservation.divertissement.descriptionSuccincte}}

                <div>
                    {{'Le' | traduction}}
                    <b>{{reservation.divertissement.date | date: 'yyyy-MM-dd'}} </b>
                </div>
            </ng-container>
            <ng-container *ngIf="reservation.divertissementItem">
                {{fuseau.toUpperCase() === 'EN' ? (reservation.divertissementItem.titreENG ? reservation.divertissementItem.titreENG: reservation.divertissementItem.titre) : reservation.divertissementItem.titre}}

            </ng-container>
            <div *ngIf="reservation.sejour">
                {{fuseau.toUpperCase() === 'EN' ? (reservation.sejour.descriptionSuccincteENG ? reservation.sejour.descriptionSuccincteENG: reservation.sejour.descriptionSuccincte) : reservation.sejour.descriptionSuccincte}}

            </div>
            <ng-container *ngIf="reservation.locationVoiture">
                {{reservation.locationVoiture.type}}
                <span *ngIf="reservation.locationVoiture.type === 'interurbain'">
                    : {{reservation.locationVoiture.depart}} - {{reservation.locationVoiture.arrivee}}
                </span>
                <span *ngIf="reservation.locationVoiture.type === 'location'">
                    : {{reservation.locationVoiture.ville}}
                </span>
            </ng-container>
        </div>
    </div>
    <div class="cell-lg-3">
        <div class="titre2 prix shadow-1" style="">
            <b>
                {{reservation.cout | conversion  | currency:devise:'symbol':'1.2-2':fuseau}}
            </b>
        </div>
    </div>
</div>

<div class="sejour_duree">
    <div class="row">
        <div class="cell-lg-3 cell-6">
            <div class="petit-padding">
                <ng-container *ngIf="reservation.hebergement">
                    {{'Hébergement' | traduction}}
                </ng-container>
                <ng-container *ngIf="reservation.transport">
                    {{'Transport' | traduction}}
                </ng-container>
                <ng-container *ngIf="reservation.divertissement">
                    {{'Divertissement' | traduction}}
                </ng-container>
                <ng-container *ngIf="reservation.divertissementItem">
                    {{'Divertissement' | traduction}}
                </ng-container>
                <ng-container *ngIf="reservation.sejour">
                    {{'Séjour' | traduction}}
                </ng-container>
                <ng-container *ngIf="reservation.locationVoiture">
                    {{'Location de voiture' | traduction}}
                </ng-container>
            </div>
        </div>
        <div class="cell-lg-3 cell-6">
            <div>
                <ng-container *ngIf="reservation.hebergement">
                    {{'Durée' | traduction}} : {{days}} {{'jours' | traduction}}
                </ng-container>
                <ng-container *ngIf="reservation.sejour">
                    {{'Durée séjour' | traduction}} : {{days}} {{'jours' | traduction}}
                </ng-container>
                <ng-container *ngIf="reservation.locationVoiture">
                    {{'Durée de la location' | traduction}} : {{days}} {{'jours' | traduction}}
                </ng-container>
                <ng-container *ngIf="reservation.divertissement">
                    {{reservation.divertissement.date | date: 'yyyy-MM-dd'}}
                </ng-container>
                <ng-container *ngIf="reservation.transport">
                    {{reservation.transport.date | date: 'yyyy-MM-dd HH:mm'}}
                </ng-container>
                <ng-container *ngIf="reservation.divertissementItem">
                    Attraction
                </ng-container>
            </div>
        </div>
        <div class="cell-lg-3 cell-6">
            <div class="">
                <ng-container *ngIf="reservation.transport">
                    <ng-container *ngIf="reservation.transport.depart.trajet.villeDepart !== reservation.transport.depart.trajet.villeArrivee">
                        {{reservation.personnes}}
                        <ng-container *ngIf="reservation.personnes > 1">
                            {{'Adultes' | traduction}}
                        </ng-container>
                        <ng-container *ngIf="!(reservation.personnes > 1)">
                            {{'Adulte' | traduction}}
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="reservation.transport.depart.trajet.villeDepart === reservation.transport.depart.trajet.villeArrivee">
                        {{reservation.personnes}}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!(reservation.transport)">
                    <ng-container *ngIf="reservation.personnes > 1">
                        {{'Adultes' | traduction}}
                    </ng-container>
                    <ng-container *ngIf="!(reservation.personnes > 1)">
                        {{'Adulte' | traduction}}
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="cell-lg-3 cell-6">
            <div>
                <ng-container *ngIf="reservation.transport">
                    <ng-container *ngIf="reservation.transport.depart.trajet.villeDepart !== reservation.transport.depart.trajet.villeArrivee">
                        {{reservation.enfants ? reservation.enfants : 0}} {{'Enfants' | traduction}}
                    </ng-container>
                    <ng-container *ngIf="reservation.transport.depart.trajet.villeDepart === reservation.transport.depart.trajet.villeArrivee">

                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!(reservation.transport)">
                    {{reservation.enfants ? reservation.enfants : 0}} {{'Enfants' | traduction}}
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div *ngIf="reservation.hebergement" class="row" style="margin-top: 20px;">
    <div class="cell-lg-3 cell-6">
        <div>
            <b>
                Arrivée
            </b>
        </div>
        <div>
            <ng-container *ngIf="reservation.hebergement">
                {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy': '' : fuseau}}
            </ng-container>
        </div>
    </div>
    <div class="cell-lg-3 cell-6">
        <div>
            <b>
                {{'Départ' | traduction}}
            </b>
        </div>
        <div>
            <ng-container *ngIf="reservation.hebergement">
                {{toDate(reservation.dateFin) | date: 'EEEE dd MMMM yyyy': '' : fuseau}}
            </ng-container>
            <ng-container *ngIf="!reservation.hebergement">
                {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy': '' : fuseau}}
            </ng-container>
        </div>
    </div>
    <div class="cell-lg-2">
        <div style="padding-top: 5px;" *ngIf="reservation.hebergement">
            <button (click)="edit(reservation)" class="button outline warning" style="border-color:  rgb(48, 164, 221); color:  rgb(48, 164, 221);">
                {{'Changer les dates' | traduction}}
            </button>
        </div>
    </div>
    <div class="cell-lg-4">
        <!-- <h3 class="muanza-text-right" style="margin: 0; padding:0">
            {{reservation.cout | conversion | currency:devise:'symbol':'1.2-2':fuseau}}
        </h3> -->
    </div>
</div>
<div *ngIf="reservation.locationVoiture" class="row" style="margin-top: 20px;">
    <div class="cell-lg-3 cell-6">
        <div>
            <b>
                {{'Début' | traduction}}
            </b>
        </div>
        <div>
            <ng-container>
                {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy': '' : fuseau}} {{'à' | traduction}} {{toDate(reservation.dateDebut) | date: 'HH:mm'}}
            </ng-container>
        </div>
    </div>
    <div class="cell-lg-3 cell-6">
        <div>
            <b>
                {{'Fin' | traduction}}
            </b>
        </div>
        <div>
            <ng-container>
                {{toDate(reservation.dateFin) | date: 'EEEE dd MMMM yyyy': '' : fuseau}} à {{toDate(reservation.dateFin) | date: 'HH:mm'}}
            </ng-container>
        </div>
    </div>
    <div class="cell-lg-2">
        <div style="padding-top: 5px;">
            <button *ngIf="reservation.hebergement" (click)="edit(reservation)" class="button outline warning" style="border-color:  rgb(48, 164, 221); color:  rgb(48, 164, 221);">
                {{'Changer les dates' | traduction}}
            </button>
        </div>
    </div>
    <div class="cell-lg-4">
        <!-- <h3 class="muanza-text-right" style="margin: 0; padding:0">
            {{reservation.cout | currency:devise:'symbol':'1.2-2':fuseau}}
        </h3> -->
    </div>
</div>
<div *ngIf="reservation.sejour" class="row" style="margin-top: 20px;">
    <div class="cell-lg-3 cell-6">
        <div>
            <b>
                {{'Arrivée' | traduction}}
            </b>
        </div>
        <div>
            <ng-container *ngIf="reservation.sejour">
                {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy': '' : fuseau}}
            </ng-container>
        </div>
    </div>
    <div class="cell-lg-3 cell-6">
        <div>
            <b>
                {{'Départ' | traduction}}
            </b>
        </div>
        <div>
            <ng-container *ngIf="reservation.sejour">
                {{toDate(reservation.dateFin) | date: 'EEEE dd MMMM yyyy': '' : fuseau}}
            </ng-container>
            <ng-container *ngIf="!reservation.sejour">
                {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy': '' : fuseau}}
            </ng-container>
        </div>
    </div>
    <div class="cell-lg-2">
        <div style="padding-top: 5px;">
            <button *ngIf="reservation.hebergement" (click)="edit(reservation)" class="button outline warning" style="border-color:  rgb(48, 164, 221); color:  rgb(48, 164, 221);">
                {{'Changer les dates' | traduction}}
            </button>
        </div>
    </div>
    <div class="cell-lg-4">
        <!-- <h3 class="muanza-text-right" style="">
            {{reservation.cout | currency:devise:'symbol':'1.2-2':fuseau}}
        </h3> -->
    </div>
</div>
<div *ngIf="reservation.divertissement" class="row" style="margin-top: 20px;">
    <div class="cell-lg-3 cell-6">
        <div>
            <b>
                {{'Arrivée' | traduction}}
            </b>
        </div>
        <div>
            <div>
                <b>{{reservation.divertissement.date | date: 'yyyy-MM-dd HH:mm'}} </b>
            </div>
        </div>
    </div>
    <div class="cell-lg-3 cell-6">
    </div>
    <div class="cell-lg-2">
    </div>
    <div class="cell-lg-4">
        <!-- <h3 class="muanza-text-right" style="margin: 0; padding:0">
            {{reservation.cout | currency:devise:'symbol':'1.2-2':fuseau}}
        </h3> -->
    </div>
</div>
<div *ngIf="reservation.divertissementItem" class="row" style="margin-top: 20px;">
    <div class="cell-lg-3 cell-6">
        <div>
            <b>
                Date
            </b>
        </div>
        <div>
            <div>
                {{reservation.dateDebut | date: 'yyyy-MM-dd HH:mm'}}
            </div>
        </div>
    </div>
    <div class="cell-lg-3 cell-6">
    </div>
    <div class="cell-lg-2">
    </div>
</div>
<div *ngIf="reservation.transport" class="row" style="margin-top: 20px;">
    <div class="cell-lg-3 cell-6">
        <div>
            <b>
                {{'Date de Départ' | traduction}}
            </b>
        </div>
        <div>
            {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy': '' : fuseau}}
        </div>
        <h2 style="margin-top: 0px;">
            {{toDate(reservation.dateDebut) | date: 'HH:mm': '' : fuseau}}
        </h2>
    </div>
    <div class="cell-lg-3 cell-6">
        <div *ngIf="reservation.transport.dateRetour">
            <div>
                <b>
                    {{'Date de retour' | traduction}}
                </b>
            </div>
            <div>
                {{toDate(reservation.transport.dateRetour) | date: 'EEEE dd MMMM yyyy': '' : fuseau}}
            </div>
            <h2 style="margin-top: 0px;">
                {{toDate(reservation.transport.dateRetour) | date: 'HH:mm' : fuseau}}
            </h2>
        </div>
    </div>
    <div class="cell-lg-2">
    </div>
    <div class="cell-lg-4 muanza-text-right">

        <div *ngIf="reservation.transport.retour">
            <span class="allerretour">
                {{'Aller Retour' | traduction}}
            </span>
        </div>
    </div>
</div>