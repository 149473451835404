<div class="page-transport">
    <div class="entete">

        <div class="entete__background interurbain">
        </div>
        <div class="entete__contenu interurbain">
            <div style="margin-left: -15px; margin-bottom: -20px; margin-top: 0px;">
                <ul class="breadcrumbs" style="background-color: rgba(0,0,0,0);">
                    <li class="page-item"><a routerLink="accueil" class="page-link">{{'Accueil' | traduction}}</a></li>
                    <li class="page-item"><a routerLink="/offres/transport" class="page-link">{{'Transports' |
                            traduction}}</a>
                    </li>
                    <li class="page-item">
                        <a routerLink="/offres/transport/location/categorie" class="page-link">
                            {{'Location de voiture' | traduction}}
                        </a>
                    </li>
                    <li class="page-item">
                        <a class="page-link">
                            {{voiture ? voiture.modele : 'Veuillez patienter' | traduction}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="row no-gap">
                <div class="cell-lg-8">
                    <div class="titre">
                        {{'Location de voiture' | traduction}} : {{voiture ? voiture.modele : 'Veuillez patienter'}}
                    </div>
                    <div style="margin-top: 0px;">
                        <span class="">
                            <input type="radio" (change)="handleChange($event)" value="interurbain" [(ngModel)]="type"
                                data-role="radio" [attr.data-caption]="'Hors de la ville' | traduction">
                        </span>
                        <span class="">
                            <input type="radio" (change)="handleChange($event)" value="location" [(ngModel)]="type"
                                data-role="radio" [attr.data-caption]="'Dans la ville' | traduction">
                        </span>
                    </div>
                </div>
                <div class="cell-lg-4 text-right">
                    <div class="titre">

                        <div *ngIf=" type === 'location' " class="">
                            {{voiture.cout | currency:devise:'symbol':'1.2-2':fuseau}}
                        </div>
                        <div *ngIf=" type === 'interurbain' " class="">
                            <ng-container *ngIf="voiture.coutInterurbain">
                                {{voiture.coutInterurbain | currency:devise:'symbol':'1.2-2':fuseau}}
                            </ng-container>
                            <ng-container *ngIf="!voiture.coutInterurbain">
                                {{voiture.cout | currency:devise:'symbol':'1.2-2':fuseau}}
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="contenu">
        <div *ngIf=" type === 'interurbain' " class="formulaire-recherche" style="background-color: white;">
            <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                <div class="row no-gap shadow-3">
                    <div class="cell-lg-3 bordure-orange-debut">
                        <div *ngIf="villes.length > 0">
                            <select formControlName="depart" [attr.data-prepend]="'Ville de départ' | traduction" data-role="select">
                                <option value="">{{'Toutes les villes' | traduction}}</option>
                                <option *ngFor="let v of villes" [value]="v.nom">
                                    {{v.nom}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="villes.length === 0">
                            <input data-role="input">
                        </div>
                    </div>
                    <div class="cell-lg-3 bordure-orange-fin">
                        <div *ngIf="villes.length > 0">
                            <select formControlName="arrivee" [attr.data-prepend]="'Ville d`arrivée' | traduction" data-role="select">
                                <option value="">{{'Toutes les villes' | traduction}}</option>
                                <option *ngFor="let v of villes" [value]="v.nom">
                                    {{v.nom}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="villes.length === 0">
                            <input data-role="input">
                        </div>
                    </div>
                    <div class="cell-lg-3 bordure-orange-milieu">
                        <div>
                            <input #date data-role="calendarpicker" [attr.data-prepend]="'Date de début' | traduction">
                        </div>
                    </div>
                    <div class="cell-lg-1 bordure-orange-fin">
                        <div>
                            <select #heure formControlName="heure">
                                <option value="oui">--:--</option>
                                <option *ngFor="let h of heures" [ngValue]="h">{{h}}</option>
                            </select>
                        </div>
                        <div class="icone-heure" (click)="selectionnerHeure()">
                            <span class="mif-alarm"></span>
                        </div>
                    </div>
                    <div class="cell-lg-2 bordure-orange-fin bg-dark">
                        <a (click)="onSubmitInterurbain()" class="button warning fg-white" style="width: 100%; background-color: rgb(48, 164, 221);">
                            {{'Réserver' | traduction}}
                        </a>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf=" type === 'location' " class="formulaire-recherche" style="background-color: white;">
            <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                <div class="row no-gap shadow-3">
                    <div class="cell-lg-3 bordure-orange-debut">

                        <div *ngIf="villes.length > 0">
                            <select formControlName="ville" [attr.data-prepend]="'Ville d`arrivée' | traduction" data-role="select">
                                <option value="">Toutes les villes</option>
                                <option *ngFor="let v of villes" [value]="v.nom">
                                    {{v.nom}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="cell-lg-3 bordure-orange-milieu">
                        <div>
                            <input #debut data-clear-button="false" data-role="calendarpicker" [attr.data-prepend]="'Date début' | traduction">
                        </div>
                    </div>
                    <div class="cell-lg-1 bordure-orange-fin">
                        <div style="position: relative;">
                            <select formControlName="heureDebut">
                                <option value="00:00">--:--</option>
                                <option *ngFor="let h of heures" [ngValue]="h">{{h}}</option>
                            </select>
                        </div>
                        <div class="icone-heure" (click)="selectionnerHeureDebut()">
                            <span class="mif-alarm"></span>
                        </div>
                    </div>
                    <div class="cell-lg-3 bordure-orange-milieu">
                        <div>
                            <input #fin data-clear-button="false" data-role="calendarpicker" [attr.data-prepend]="'Date fin' | traduction">
                        </div>
                    </div>
                    <div class="cell-lg-1 bordure-orange-fin">
                        <div style="text-align: center;">
                            <select formControlName="heureFin">
                                <option value="00:00">--:--</option>
                                <option *ngFor="let h of heures" [ngValue]="h">{{h}}</option>
                            </select>
                        </div>
                        <div class="icone-heure" (click)="selectionnerHeureDebut()">
                            <span class="mif-alarm"></span>
                        </div>
                    </div>
                    <div class="cell-lg-1 bordure-orange-fin bg-dark">
                        <button class="button warning" style="width: 100%; background-color: rgb(48, 164, 221);">
                            {{'Réserver' | traduction}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="voitures">
            <div class="row" *ngIf="voiture">
                <div class="cell-lg-4">
                    <div style="margin-bottom: 20px;">
                        <div class="voiture-categorie">
                            {{voiture.categorie}}
                        </div>
                        <div class="voiture-modele">
                            {{voiture.modele}}
                        </div>
                        <div class="">
                            {{voiture.sieges}} {{'sièges' | traduction}} | {{voiture.portieres}} {{'portières' | traduction}} | {{voiture.transmission | traduction}}
                        </div>
                    </div>
                    <div [innerHTML]="langue.toUpperCase() === 'ENG' ? (voiture.descriptionENG ? voiture.descriptionENG: voiture.description) : voiture.description">
                    </div>
                </div>
                <div class="cell-lg-8">
                    <div class="trap-image-big">
                        <ng-container *ngFor="let image of voiture.images; let i = index">

                            <div *ngIf="i === indexImages" class="trap-image-big animer" [ngStyle]="{'background-image': 'url(' + voiture.images[i] +')'}">

                            </div>

                        </ng-container>
                    </div>
                    <div class="row" style="margin-top: 5px;">
                        <div (click)="choisir(i)" class="cell-lg-1 cell-3" *ngFor="let image of voiture.images; let i = index">
                            <div [ngClass]="{'actuelle': i === indexImages}" style="height: 60px; cursor: pointer; background-size: cover; background-position: center center;" [ngStyle]="{'background-image': 'url(' + image +')'}">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="height: 50vh;">

</div>

<div id="demoDialog2" class="dialog" data-role="dialog" style="margin-top: 0px;">
    <div class="dialog-title">{{'Voyage Retour' | traduction}}</div>
    <div class="dialog-content">
        <div style="margin-top: 0px; margin-bottom: 40px;">
            <div>
                {{'Souhaitez-vous effectuer un voyage retour ?' | traduction}}
            </div>
            <div>
                <div class="row">
                    <div class="cell-lg-12">
                        <input #calendarpickerlocale2 data-role="calendarpicker" data-cls-calendar="compact" data-prepend="Date">
                    </div>
                    <div class="cell-lg-12">
                        <select [(ngModel)]="retourHeure" [attr.data-prepend]="'Heure' | traduction" data-role="select">
                            <option [ngValue]="heure" *ngFor="let heure of heures">
                                {{heure}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="dialog-actions">

        <a (onclick)="test()" (click)="test()" class="button">{{'Non, merci' | traduction}}</a>

        <button (click)="saveWithRetour()" class="button warning js-dialog-close" style="background-color: rgb(48, 164, 221);">
            {{'Enregistrer' | traduction}}
        </button>
    </div>
</div>