<div class="page-transport">
    <div class="entete" style="">

        <div class="entete__background" [ngClass]="{'interurbain': type==='interurbain', 'location': type==='location'}">
        </div>
        <div class="entete__contenu" [ngClass]="{'interurbain': type==='interurbain', 'location': type==='location'}">
            <div class="row no-gap">
                <div class="cell-lg-12">
                    <div style="margin-left: -15px; margin-bottom: -20px; margin-top: 0px;">
                        <ul class="breadcrumbs" style="background-color: rgba(0,0,0,0);">
                            <li class="page-item"><a routerLink="accueil" class="page-link">{{'Accueil' | traduction}}</a></li>
                            <li class="page-item">
                                <a routerLink="/offres/transport" class="page-link">{{'Transports' | traduction}}</a>
                            </li>
                            <li class="page-item"><a routerLink="/offres/transport/recherche" class="page-link">{{'Transports interurbains' | traduction}}</a></li>
                        </ul>
                    </div>
                    <div class="titre">
                        {{'Transports interurbains' | traduction}}
                    </div>
                </div>
            </div>
            <div style="display: none;" class="row no-gap">
                <div class="cell-lg-6">
                    <div class="titre fg-white">
                        <span class="">
                            <input type="radio" (change)="handleChange($event)" value="interurbain" [(ngModel)]="type"
                                data-role="radio" data-caption="Transport interurbain">
                        </span>
                        <span class="">
                            <input type="radio" (change)="handleChange($event)" value="location" [(ngModel)]="type"
                                data-role="radio" data-caption="Location de voiture">
                        </span>
                    </div>
                </div>
                <div class="cell-lg-6">
                    <app-admin>
                        <div class="administration">
                            <button class="button" (click)="trajet()">
                                Nouveau trajet
                            </button>
                            <button class="button" (click)="agences()">
                                Agences
                            </button>
                            <button class="button" (click)="agence()">
                                Nouvelle agence
                            </button>
                            <button class="button" (click)="lesdepart()">
                                Nouveau départ
                            </button>
                        </div>
                    </app-admin>
                </div>
            </div>
        </div>
    </div>

    <div class="contenu">
        <div *ngIf=" type==='interurbain' " class="formulaire-recherche">

            <div class="row no-gap shadow-3">
                <div class="cell-lg-5 bordure-orange-debut">
                    <div *ngIf="villes.length > 0">
                        <select [(ngModel)]="depart" [attr.data-prepend]="'Ville de départ' | traduction" data-role="select">
                                <option *ngFor="let v of villes" [value]="v.nom">
                                    {{v.nom}}
                                </option>
                            </select>
                    </div>
                    <div *ngIf="villes.length === 0">
                        <input data-role="input">
                    </div>
                </div>
                <div class="cell-lg-5 bordure-orange-fin">
                    <div>
                        <div *ngIf="villes.length > 0">
                            <select [(ngModel)]="arrivee" [attr.data-prepend]="'Ville d`arrivée' | traduction" data-role="select">
                                    <option *ngFor="let v of villes" [value]="v.nom">
                                        {{v.nom}}
                                    </option>
                                </select>
                        </div>
                        <div *ngIf="villes.length === 0">
                            <input data-role="input">
                        </div>
                    </div>
                </div>
                <div class="cell-lg-2 bordure-orange-fin bg-dark">
                    <button (click)="onFormSubmit()" class="button warning" style="width: 100%; background-color: rgb(48, 164, 221);">
                            {{'Rechercher'  | traduction}}
                        </button>
                </div>
            </div>

        </div>
        <div *ngIf=" type==='location' " class="formulaire-recherche">
            <form [formGroup]="form2" (ngSubmit)="onFormSubmit2()">
                <div class="row no-gap shadow-3">
                    <div class="cell-lg-10 bordure-orange-debut">
                        <div>
                            <input data-role="input" formControlName="ville" data-prepend="Ville de location du véhicule">
                        </div>
                    </div>
                    <div class="cell-lg-2 bordure-orange-fin" style="background-color:  rgb(48, 164, 221);">
                        <button class="button warning" style="width: 100%; background-color:  rgb(48, 164, 221);">
                            Rechercher
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contenu" [ngClass]="{'visible': resultatsVisible, 'invisible': !resultatsVisible}">
        <app-admin>
            <div class="trajets">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Ville de départ</th>
                            <th>Ville d'arrivée</th>
                            <th>Supprimer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let trajet of trajets" style="cursor: pointer;">
                            <td (click)="departs(trajet)">{{ trajet.id }}</td>
                            <td (click)="departs(trajet)">{{ trajet.villeDepart | uppercase}}</td>
                            <td (click)="departs(trajet)">{{ trajet.villeArrivee | uppercase}}</td>
                            <td>
                                <button class="button" (click)="modifier(trajet)">
                                    Modifier
                                </button>
                                <button style="margin-left: 10px;" class="button alert" (click)="supprimer(trajet)">
                                    Supprimer
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-admin>
    </div>
</div>
<div style="height: 50vmax;">

</div>