export const DATATABLES_OPTIONS_LANGUAGE = {
    emptyTable: 'Aucune donnée disponible dans le tableau',
    info: 'Affichage de l\'élément _START_ à _END_ sur _TOTAL_ éléments',
    infoEmpty: 'Affichage de l\'élément 0 à 0 sur 0 élément',
    infoFiltered: '(filtré à partir de _MAX_ éléments au total)',
    infoPostFix: '',
    lengthMenu: 'Afficher _MENU_ éléments',
    loadingRecords: 'Chargement...',
    processing: 'Traitement...',
    search: 'Rechercher :',
    zeroRecords: 'Aucun élément correspondant trouvé',
    paginate: {
        first: 'Premier',
        last: 'Dernier',
        next: 'Suivant',
        previous: 'Précédent'
    },
    aria: {
        sortAscending: ': activer pour trier la colonne par ordre croissant',
        sortDescending: ': activer pour trier la colonne par ordre décroissant'
    }
};
