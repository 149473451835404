import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  admin() {
    this.router.navigate(['admin', 'connexion']);
  }

  goToFacebook() {
    const lien = 'https://web.facebook.com/profile.php?id=100083935126275';
    window.open(lien);
  }

  goToInstagram() {
    const lien = 'https://www.instagram.com/trapyourtrip/';
    window.open(lien);
  }

}
