<div class="page" style="min-height: 100vh; padding-top: 10px;">
    <div style="margin-top: 0px; margin-bottom: 20px;">
        <div data-role="stepper" data-steps="3" data-step="3" data-view="diamond" data-cls-step="rounded" data-cls-complete="bg-green" data-cls-current="bg-blue">
        </div>
    </div>
    <div class="entete" *ngIf="reservation">

        <app-display-reservation-description [reservation]="reservation"></app-display-reservation-description>

        <div class="shadow-1" style="padding:20px; padding-top: 15px; margin-top: 20px">
            <div>
                <h3 style="margin:0; padding:0;">
                    {{'Responsable de la réservation' | traduction}}
                </h3>
                <div style="color: #444;">
                    <small>
                        {{'Les informations suivantes sont strictement confidentielles et ne seront ni utilisées ni vendues' | traduction}}
                    </small>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="cell-lg-3 cell-6">
                        <b>{{'Noms et prénoms' | traduction}}</b>
                        <div>
                            {{reservation.responsable.nom}} {{reservation.responsable.prenom}}
                        </div>
                    </div>
                    <div class="cell-lg-3 cell-6">
                        <b>Email</b>
                        <div>
                            {{reservation.responsable.email}}
                        </div>
                    </div>
                    <div class="cell-lg-2">
                        <b>{{'Télephone' | traduction}}</b>
                        <div>
                            {{reservation.responsable.indicatif}} {{reservation.responsable.tel}}
                        </div>
                    </div>
                    <div class="cell-lg-4">
                        <b>{{'Pièce d`identification' | traduction}}</b>
                        <div>
                            <i *ngIf="reservation.responsable.typepiece">
                                <ng-container *ngIf="reservation.responsable.typepiece === 'cni'">
                                    {{"Carte Nationale d'Identité" | traduction}}
                                </ng-container>
                                <ng-container *ngIf="reservation.responsable.typepiece === 'passeport'">
                                    {{'Passeport' | traduction}}
                                </ng-container>
                                <ng-container *ngIf="reservation.responsable.typepiece === 'sejour'">
                                    {{'Carte de séjour' | traduction}} 
                                </ng-container>
                                <ng-container *ngIf="reservation.responsable.typepiece === 'permis'">
                                    {{'Permis de conduire' | traduction}}
                                </ng-container>

                            </i> {{reservation.responsable.numero}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 20px">
            <button (click)="panier()" class="button warning" style="background-color:  rgb(48, 164, 221); color: #fff;">
                {{'Passer au panier' | traduction}}
            </button>
        </div>

    </div>
    <div class="contenu">

    </div>
</div>