<div class="page-transport">
    <div class="entete">

        <div class="entete__background interurbain">
        </div>
        <div class="entete__contenu interurbain">
            <div class="row no-gap">
                <div class="cell-lg-12">
                    <div style="margin-left: -15px; margin-bottom: -20px; margin-top: 0px;">
                        <ul class="breadcrumbs" style="background-color: rgba(0,0,0,0);">
                            <li class="page-item"><a routerLink="accueil" class="page-link">{{'Accueil' |
                                    traduction}}</a></li>
                            <li class="page-item"><a routerLink="/offres/transport" class="page-link">{{'Transports' |
                                    traduction}}</a></li>
                            <li class="page-item"><a class="page-link">{{'Location de voiture' | traduction}}</a></li>
                        </ul>
                    </div>
                    <div class="titre">
                        {{'Location de voiture' | traduction}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contenu">
        <div class="formulaire-recherche">
            <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                <div class="row no-gap shadow-3">
                    <div class="cell-lg-5 bordure-orange-debut">
                        <div>
                            <input formControlName="rechercher" data-role="input"
                                [attr.data-prepend]="'Rechercher' | traduction">
                        </div>
                    </div>
                    <div *ngIf="villes.length>0" class="cell-lg-3 bordure-orange-fin">
                        <div>
                            <select formControlName="ville" data-role="select"
                                [attr.data-prepend]="'Villes' | traduction">
                                <option value="0">Toutes</option>
                                <option *ngFor="let c of villes" [value]="c.nom">{{c.nom}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="cell-lg-3 bordure-orange-fin">
                        <div>
                            <select formControlName="transmission" data-role="select"
                                [attr.data-prepend]="'Transmission' | traduction">
                                <option value="0">Toutes</option>
                                <option value="Automatique">
                                    {{'Automatique' | traduction}}
                                </option>
                                <option value="Manuelle">
                                    {{'Manuelle' | traduction}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="cell-lg-1 bordure-orange-fin bg-dark">
                        <button class="button warning" style="width: 100%; background-color: rgb(48, 164, 221);">
                            {{'Rechercher' | traduction}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="voitures">
            <div class="row">
                <div (click)="voir(v)" *ngFor="let v of resultats" class="cell-lg-4">
                    <app-display-voiture [voiture]="v"></app-display-voiture>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="height: 50vh;">

</div>