<div class="page-sejour" *ngIf="prestataire">
    <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
        <div class="entete">
            <div class="entete__background" [ngStyle]="{'background-image': 'url('+ prestataire.photoURL +')'}">
            </div>
            <div class="entete__contenu">
                <div class="row no-gap">
                    <div class="cell-lg-9">
                        <div style="margin-left: -15px; margin-bottom: -20px;">
                            <ul class="breadcrumbs" style="background-color: rgba(0,0,0,0);">
                                <li class="page-item"><a routerLink="accueil" class="page-link">{{'Accueil' | traduction}}</a></li>
                                <li class="page-item"><a (click)="goToAll()" class="page-link">{{'Hébergement' | traduction}}</a></li>
                                <li class="page-item" *ngIf="prestataire"><a class="page-link">{{prestataire.nom}}</a>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="prestataire">
                            <div class="titre">
                                {{prestataire.nom}}
                                <a class="button small showOnMObile" (click)="rechercheFilter()" style="float: right; margin-left: 10px;">
                                    <i class="mif-search fg-blue"></i>
                                </a>
                                <a class="button small showOnMObile" (click)="toggleFilter()" style="float: right; margin-left: 10px;">
                                    <i class="mif-filter fg-blue"></i>
                                </a>
                                <span class="sejour__notation bleu" [innerHTML]="notationToStars(prestataire.notation)"></span>
                            </div>
                            <div class="sous-titre text-leader2" style="font-size: 1.1em;">
                                <ng-container *ngIf="prestataire.pays">
                                    {{prestataire.pays}},
                                </ng-container>
                                <ng-container *ngIf="prestataire.ville">
                                    {{prestataire.ville}},
                                </ng-container>
                                <ng-container *ngIf="prestataire.localisation">
                                    {{prestataire.localisation}},
                                </ng-container>
                                <ng-container *ngIf="prestataire.tel">
                                    {{prestataire.tel}},
                                </ng-container>
                                <ng-container *ngIf="prestataire.email">
                                    {{prestataire.email}}
                                </ng-container>
                            </div>
                            <div>
                                <a *ngIf="prestataire.latitude && prestataire.longitude" class="showOnMObile " style="color: rgb(48, 164, 221); font-size: 0.8em;" (click)="ouvrirGoogleMap()" target="_blank">
                                    {{'Ouvrir sur Google Map' | traduction}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="cell-lg-3 text-right hideOnMObile">
                        <div *ngIf="prestataire.latitude && prestataire.longitude" style="text-align: center; margin-top: 20px;">
                            <div style="font-size: 2.5em;">
                                <span class="mif-location" style="color: rgb(48, 164, 221);"></span>
                            </div>
                            <div>
                                <a class="text-leader2 map" style="color: #303030;" (click)="ouvrirGoogleMap()" target="_blank">
                                    {{'Ouvrir sur Google Map' | traduction}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contenu">
            <div style="margin-top: -20px; margin-bottom: 10px;">
                <div *ngIf="recherchesShowed" class="mobile-recherche">

                    <div class="row no-gap shadow-2" style="border-radius: 0px;">
                        <div class="cell-lg-9 bordure-orange-debut">
                            <div style="color: #303030">
                                <select style="color: #303030" placeholder="Ordre" [attr.data-prepend]="'Ordre' | traduction" formControlName="ordre" data-role="select" data-filter="false">
                                    <option value="null" disabled="true" [selected]="true">
                                        {{"Ordre d'affichage" | traduction}}
                                    </option>
                                    <option value="croissant">
                                        {{'Ordre croissant de prix' | traduction}}
                                    </option>
                                    <option value="decroissant">
                                        {{'Ordre décroissant de prix' | traduction}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="cell-lg-3 bordure-orange-fin radius-droit2" style="background-color: rgb(48, 164, 221);">
                            <button class="button rechercher">{{'Rechercher' | traduction}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="cell-lg-12">
                    <div class="ordre">
                    </div>
                </div>
            </div>
            <div class="sejours">
                <div class="row">
                    <div class="cell-lg-2">
                        <div *ngIf="filtersShowed" class="les">
                            <div (click)="toggleFilter()" class="fermer-options showOnMObile">

                            </div>
                            <div class="les-options">
                                <div class="les-options-contenant">
                                    <div class="les-options-contenu">
                                        <div class="options">
                                            <span class="">
                                                <input formControlName="baignoire" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Baignoire' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="wifi" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'WiFi' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="climatiseur" type="checkbox"
                                                    data-role="checkbox" [attr.data-caption]="'Climatisation' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="bureau" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Espace bureau' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="linge" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Lave linge' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="tele" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Télévision' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="insonore" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Insonorisation' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="bouilloire" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Bouilloire électrique' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="cafe" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Machine à café' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="minibar" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Mini Bar' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="litsimple" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Lit simple' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="litdouble" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Lit double' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="spa" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Spa et centre de bien être' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="forme" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Centre de remise en forme' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="navette" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Navette aéroport' | traduction">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cell-lg-7">
                        <div class="row">
                            <div class="cell-lg-6 cell-md-6" *ngFor="let hebergement of resultats">
                                <app-display-hebergement [hebergement]="hebergement"></app-display-hebergement>
                            </div>
                        </div>
                    </div>
                    <div class="cell-lg-3">
                        <div [innerHTML]="langue.toUpperCase() === 'ENG' ? (prestataire.descriptionENG ? prestataire.descriptionENG: prestataire.description) : prestataire.description" class="descrire-prestataire shadow-1">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div>
    <div style="height: 100vh;" *ngIf="!prestataire">

    </div>