import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paiement-view',
  templateUrl: './paiement-view.component.html',
  styleUrls: ['./paiement-view.component.scss']
})
export class PaiementViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
