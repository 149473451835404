<div *ngIf="transport" class="transport" (click)="ouvrir(transport.id)">
    <div class="transport__conteneur">
        <div class="transport__image" [ngStyle]="{'background-image': 'url(' + transport.images[0] +')'}">

        </div>
    </div>
    <div class="transport__description">
        {{transport.titre}}
    </div>
    <div class="transport__notation orange" [innerHTML]="notationToStars(transport.notation)"></div>
    <div class="transport__prix">
        {{transport.prixUnitaire | conversion | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
    </div>
</div>