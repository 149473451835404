<div class="page">
    <div class="entete">
        <div style="margin-top: 100px; text-align: center;">

            <h1 class="titre">
                {{'Quel type de divertissements désirez-vous ?' | traduction}}
            </h1>

            <div>
                <app-admin>
                    <div class="administration">
                        <button class="button primary" style="background-color: rgb(48, 164, 221);" (click)="nouveau()">
                            Nouveau
                        </button>
                    </div>
                </app-admin>
            </div>
        </div>
    </div>
    <div class="contenu" style="min-height: 100vh;">
        <div class="row">
            <div class="cell-lg-4">
                <div (click)="evenements()" class="categorie shadow-1">
                    <div class="evenements">
                        <div style="height: 5vh;"></div>
                    </div>
                    <div class="titre-divertissement">
                        <button class="button warning" style="background-color: rgb(48, 164, 221);">
                            {{'Evènements' | traduction}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="cell-lg-4">
                <div (click)="loisirs()" class="categorie shadow-1">
                    <div class="attractions">
                        <div style="height: 5vh;"></div>
                    </div>
                    <div class="titre-divertissement">
                        <button class="button warning" style="background-color: rgb(48, 164, 221);">
                            {{'Attractions' | traduction}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="cell-lg-4">
                <div (click)="restaurants()" class="categorie shadow-1">
                    <div class="restaurants">
                        <div style="height: 5vh;"></div>
                    </div>
                    <div class="titre-divertissement">
                        <button class="button warning" style="background-color: rgb(48, 164, 221);">
                            {{'Restaurants' | traduction}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>