<div class="page-sejour">
    <div class="entete">
        <div class="row">
            <div class="cell-lg-5">
                <div class="titre">
                    Paiement
                </div>
                <div class="sous-titre text-leader2">
                    Effectuez le paiement ici. Choisissez le mode de paiement qui vous convient
                </div>
            </div>
        </div>
    </div>
    <div class="contenu">
        <div class="row">
            <div class="cell-lg-7 showOnMObile" style="text-align: center;">
                <h4>
                    {{TOTAL | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                </h4>
            </div>

            <div class="cell-lg-7 hideOnMObile">
                <table class="table table-border cell-border">
                    <thead>
                        <tr>
                            <th>Date début</th>
                            <th>Prestation</th>
                            <th style="background-color: #fafafa;">Coût</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let reservation of reservations">
                            <td>{{reservation.dateDebut | date: 'yyyy-MM-dd'}}</td>
                            <td>
                                <span *ngIf="reservation.sejour">
                                    {{reservation.sejour.titre}}
                                </span>
                                <span *ngIf="reservation.hebergement">
                                    {{reservation.hebergement.titre}}
                                </span>
                                <span *ngIf="reservation.divertissement">
                                    {{reservation.divertissement.titre}}
                                </span>
                                <span *ngIf="reservation.transport">
                                    <ng-container
                                        *ngIf="reservation.transport.depart && reservation.transport.depart.agence">
                                        {{reservation.transport.depart.agence.nom}} :
                                        <b>
                                            {{reservation.transport.depart.modele}}
                                        </b>
                                    </ng-container>
                                </span>
                                <div *ngIf="reservation.locationVoiture">
                                    Location de voiture : {{reservation.locationVoiture.voiture.modele}}
                                    <span>
                                        {{reservation.locationVoiture.type}}
                                    </span>
                                    <span>
                                        <span *ngIf="reservation.locationVoiture.type === 'interurbain'">
                                            {{reservation.locationVoiture.depart}} -
                                            {{reservation.locationVoiture.arrivee}}
                                        </span>
                                    <span *ngIf="reservation.locationVoiture.type === 'location'">
                                            : {{reservation.locationVoiture.ville}}
                                        </span>
                                    </span>
                                    <div *ngIf="reservation.locationVoiture">
                                        Du {{toDate(reservation.dateDebut) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}} à {{toDate(reservation.dateDebut) | date: 'HH:mm'}} au {{toDate(reservation.dateFin) | date: 'EEEE dd MMMM yyyy' : 'fr-FR'}} à {{toDate(reservation.dateFin) | date: 'HH:mm'}}
                                    </div>
                                </div>
                            </td>
                            <td style="text-align: right; background-color: #fafafa;">
                                <b>
                                    {{reservation.cout | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                                </b>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th style="font-size: 1.2em;" colspan="2">Total</th>
                            <th style="font-size: 1.2em; text-align: right;background-color: #fafafa;">
                                {{TOTAL | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="cell-lg-5">
                <div class="row">
                    <div class="cell-lg-6">
                        <div>
                            <form #form action="http://trapyourtrip/cinetpay/index.php" method="post">
                                <input type="hidden" name="montant" [value]="TOTAL">
                                <button type="submit" (click)="form.submit()">
                                    Payer
                                    <img src="../../../../assets/img/orange.png" style="width: 100%;">
                                </button>
                            </form>
                        </div>
                    </div>
                    <div class="cell-lg-6">
                        <button type="submit" (click)="paiementCinet()">
                            Payer
                            <img src="../../../../assets/img/cinetpay.png" style="width: 100%;">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>