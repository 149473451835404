<app-admin>
    <div style="padding: 60px;">
        <div class="titre" *ngIf="!sejour">
            Nouveau séjour
        </div>
        <div class="titre" *ngIf="sejour">
            {{sejour.titre}}
        </div>
        <div class="text-leader2" style="margin-bottom: 20px;">
            Création d'un nouveau séjour
        </div>
        <div class="formulaire">
            <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
                <div class="bloc">
                    <h4>
                        Informations générales
                    </h4>
                    <div class="row">
                        <div class="cell-lg-8">
                            <input data-role="input" formControlName="titre" data-prepend="Titre">
                        </div>
                        <div class="cell-lg-4">
                            <input data-role="input" type="number" formControlName="notation" data-prepend="Notation">
                        </div>
                    </div>
                    <div class="row">
                        <div class="cell-lg-6">
                            <input data-role="input" formControlName="ville" data-prepend="Ville">
                        </div>
                        <div class="cell-lg-6">
                            <input data-role="input" type="number" formControlName="prixUnitaire"
                                data-prepend="Prix Unitaire">
                        </div>
                    </div>
                    <div>
                    </div>
                    <div>
                        <textarea data-role="textarea" formControlName="description"
                            data-prepend="Description"></textarea>
                    </div>
                    <div class="row">
                        <div class="cell-lg-6">
                            <input data-role="input" type="date" formControlName="dateDebut" data-prepend="Date de début">
                        </div>
                        <div class="cell-lg-6">
                            <input data-role="input" type="date" formControlName="dateFin" data-prepend="Date de fin">
                        </div>
                    </div>
                </div>
                <div class="bloc" *ngIf="prestataires">
                    <h4>
                        Hébergements
                    </h4>
                    <div class="row">
                        <div class="cell-lg-8">
                            <div class="text-leader">
                                Choississez parmi les logements disponibles,
                                le logement qui sera proposé au client durant son séjour
                            </div>
                            <div>
                                <select formControlName="prestataire" class="select input-select"
                                    style="padding-left: 20px;">
                                    <option value="0">
                                        Choisir le prestataire
                                    </option>
                                    <option *ngFor="let p of prestataires" [ngValue]="p">
                                        {{p.nom}}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <select formControlName="hebergement" class="select input-select"
                                    style="padding-left: 20px;">
                                    <option *ngIf="hebergement">
                                        {{hebergement.titre}}
                                    </option>
                                    <option value="0">
                                        Aucun hébergement
                                    </option>
                                    <option *ngFor="let h of resultatsHebergements" [ngValue]="h">
                                        {{h.titre}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="cell-lg-4">
                            <div *ngIf="hebergement">
                                <app-display-hebergement [cliquable]="false" [hebergement]="hebergement">
                                </app-display-hebergement>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bloc">
                    <h4>
                        Divertissements
                    </h4>
                    <div>
                        <select [compareWith]="compareTech" *ngIf="divertissementsGood"
                            formControlName="divertissements" data-role="select" multiple>

                            <option *ngFor="let d of divertissements" [ngValue]="d">
                                {{d.titre}}
                            </option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="cell-lg-4" *ngFor="let md of mesDivertissements">
                            <ng-container *ngIf="md.date">
                                <app-display-divertissement [cliquable]="false" [divertissement]="md">
                                </app-display-divertissement>
                            </ng-container>
                            <ng-container *ngIf="!md.date">
                                <app-display-loisir [cliquable]="false" [divertissement]="md">

                                </app-display-loisir>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="bloc">
                    <h4>
                        Images
                    </h4>
                    <div>
                        <input (change)="uploadFile($event)" data-role="file" type="file" multiple
                            data-prepend="Images">
                    </div>
                    <div class="row">
                        <div class="cell-lg-6">
                            <button class="button warning">Enregistrer</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</app-admin>