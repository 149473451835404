<div class="page-sejour">
    <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
        <div class="entete" style="background-color: #ddd;">
            <div class="entete__background">
            </div>
            <div class="entete__contenu">
                <div class="row">
                    <div class="cell-lg-8">
                        <div class="titre">
                            {{'Hébergements' | traduction}}
                            <a class="button small showOnMObile" (click)="rechercheFilter()" style="float: right; margin-left: 10px;">
                                <i class="mif-search fg-blue"></i>
                            </a>
                            <a class="button small showOnMObile" (click)="toggleFilter()" style="float: right; margin-left: 10px;">
                                <i class="mif-filter fg-blue"></i>
                            </a>
                        </div>
                        <div class="sous-titre text-leader2" style="font-size: 0.9em; padding-bottom: 5px;">
                            {{"Retrouvez nos offres d'hébergement" | traduction}}
                        </div>
                    </div>
                    <div class="cell-lg-4">
                        <app-admin>
                            <div class="administration">
                                <button class="button primary" style="background-color: rgb(48, 164, 221);" (click)="nouveau()">
                                    Nouveau
                                </button>
                            </div>
                        </app-admin>
                    </div>
                </div>
            </div>
        </div>
        <div class="contenu">

            <div *ngIf="recherchesShowed" class="mobile-recherche" style="margin-top: -30px; margin-bottom: 10px;">
                <div class="row no-gap shadow-3 radius-droit3">
                    <div class="cell-lg-3 bordure-orange-debut">
                        <div class="">
                            <input formControlName="mot" [placeholder]="'Nom de l`établissement' | traduction" class="input muanza ">
                        </div>
                    </div>
                    <div class="cell-lg-2 bordure-orange-fin">
                        <div *ngIf="villes.length === 0">
                            <input #ville formControlName="ville" data-role="input" [placeholder]="'Ville' | traduction" data-autocomplete="Douala, Yaoundé, Kribi, Limbé, Garoua, Maroua">
                        </div>
                        <div *ngIf="villes.length > 0">
                            <select #ville formControlName="ville" [attr.data-prepend]="'Ville' | traduction" data-role="select">
                                <option value="">{{'Toutes les villes' | traduction}}</option>
                                <option *ngFor="let v of villes" [value]="v.nom">
                                    {{v.nom}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="cell-lg-3 cell-6 bordure-orange-fin">
                        <select placeholder="Ordre" formControlName="ordre" data-role="select" data-filter="false">
                            <option value="null" disabled="true" [selected]="true">
                                {{"Ordre d'affichage" | traduction}}
                            </option>
                            <option value="croissant">
                                {{mobile ? "Notation +": "Ordre croissant de notation" | traduction}}
                                
                            </option>
                            <option value="decroissant">
                                {{mobile ? "Notation -": "Ordre décroissant de notation" | traduction}}
                                
                            </option>
                            <option value="croissant-prix">
                                {{mobile ? "Prix +": "Ordre croissant de prix" | traduction}}
                                
                            </option>
                            <option value="decroissant-prix">
                                {{mobile ? "Prix -": "Ordre décroissant de prix" | traduction}}
                               
                            </option>
                        </select>
                    </div>
                    <div class="cell-lg-3 cell-6 bordure-orange-fin">
                        <select placeholder="Logement" formControlName="nature" data-role="select" data-filter="false">
                            <option value="null" disabled="true" [selected]="true">
                                {{"Type d'hébergement" | traduction}}
                            </option>
                            <option value="tous">
                                {{'Tous' | traduction}}
                            </option>
                            <option value="hotel">
                                {{'Hôtel' | traduction}}
                            </option>
                            <option value="villa">
                                {{'Appartement' | traduction}}
                            </option>
                            <option value="lodge">
                                {{'Lodge'}}
                            </option>
                        </select>
                    </div>
                    <div class="cell-lg-1 bordure-orange-fin radius-droit2 bg-couleur">
                        <button class="button radius-droit2 bg-couleur" style="color: #fff;  width: 100%;">Rechercher</button>
                    </div>
                    <div class="showOnMObile mobile-recherche-fermer cell-lg-2">
                        <a (click)="rechercheFilter()" class="button dark" style="color: #fff;  width: 100%;">Fermer</a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="cell-lg-12 text-right">
                    <div class="ordre">

                    </div>
                </div>
            </div>
            <div class="sejours">
                <div class="row">
                    <div class="cell-lg-2">
                        <div *ngIf="filtersShowed" class="les">
                            <div (click)="toggleFilter()" class="fermer-options showOnMObile">

                            </div>
                            <div class="les-options">
                                <div class="les-options-contenant">
                                    <div class="fermer-filtre" (click)="toggleFilter()">
                                        <button class="button small">
                                            X
                                        </button>
                                    </div>
                                    <div class="les-options-contenu">

                                        <div class="options-titres">
                                            {{'Filtres populaires' | traduction}}
                                        </div>
                                        <div class="options">
                                            <span class="">
                                                <input formControlName="piscine" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Piscine' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="plage" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Bord de mer' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="spa" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Spa et centre de bien être' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="petitdej" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Petit déjeuner compris' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="hotel" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Hôtel' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="appartement" type="checkbox"
                                                    data-role="checkbox" [attr.data-caption]="'Appartement' | traduction">
                                            </span>
                                        </div>

                                        <div class="options-titres">
                                            {{'Type d’établissement' | traduction}}
                                        </div>
                                        <div class="options">
                                            <span class="">
                                                <input formControlName="hotel" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Hôtel' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="appartement" type="checkbox"
                                                    data-role="checkbox" [attr.data-caption]="'Appartement' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="lodge" type="checkbox"
                                                    data-role="checkbox" [attr.data-caption]="'Lodge' | traduction">
                                            </span>
                                        </div>

                                        <div class="options-titres">
                                            {{'Etoiles' | traduction}}
                                        </div>
                                        <div class="etoiles">
                                            <span (click)="changerEtoiles(1)" (mouseenter)="changerEtoilesIntention(1)" (mouseleave)="reinitEtoiles()" [ngClass]="{'mif-star-empty': triEtoilesIntention < 1, 'mif-star-full': triEtoilesIntention > 0 || triEtoiles > 0}"></span>

                                            <span (click)="changerEtoiles(2)" (mouseenter)="changerEtoilesIntention(2)" (mouseleave)="reinitEtoiles()" [ngClass]="{'mif-star-empty': triEtoilesIntention < 2, 'mif-star-full': triEtoilesIntention > 1 || triEtoiles > 1}"></span>

                                            <span (click)="changerEtoiles(3)" (mouseenter)="changerEtoilesIntention(3)" (mouseleave)="reinitEtoiles()" [ngClass]="{'mif-star-empty': triEtoilesIntention < 3, 'mif-star-full': triEtoilesIntention > 2 || triEtoiles > 2}"></span>

                                            <span (click)="changerEtoiles(4)" (mouseenter)="changerEtoilesIntention(4)" (mouseleave)="reinitEtoiles()" [ngClass]="{'mif-star-empty': triEtoilesIntention < 4, 'mif-star-full': triEtoilesIntention > 3 || triEtoiles > 3}"></span>

                                            <span (click)="changerEtoiles(5)" (mouseenter)="changerEtoilesIntention(5)" (mouseleave)="reinitEtoiles()" [ngClass]="{'mif-star-empty': triEtoilesIntention < 5, 'mif-star-full': triEtoilesIntention > 4 || triEtoiles > 4}"></span>

                                        </div>

                                        <div class="options-titres">
                                            {{'Repas' | traduction}}
                                        </div>
                                        <div class="options">
                                            <span class="">
                                                <input formControlName="petitdej" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Petit déjeuner compris' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="dej" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Petit déjeuner et déjeuner compris' | traduction">
                                            </span>
                                            <span class="">
                                                <input formControlName="cuisine" type="checkbox" data-role="checkbox"
                                                    [attr.data-caption]="'Logement avec cuisine' | traduction">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cell-lg-10">
                        <div class="row muanza-padding-mobile">
                            <div class="cell-lg-4 cell-md-4" *ngFor="let prestataire of resultats">
                                <app-display-prestataire [prestataire]="prestataire"></app-display-prestataire>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>