<div #menuMobile data-role="appbar" [ngClass]="{'shadow-1': !primarycolor || mobile}" data-expand-point="md" class="muanza-appbar" style="">
    <a href="#" class="brand no-hover">
        <img class="image-logo" src="../../../assets/img/Trap Your Trip Logo 2.png">
    </a>

    <ul class="app-bar-menu ml-auto" [ngClass]="{'onscroll': !primarycolor || mobile}" (click)="hamburger()">
        <li>
            <a routerLink="accueil" routerLinkActive="active">
                <span class="mif-home"></span> {{'Accueil' | traduction:langue }}

            </a>
        </li>
        <li>
            <a [ngClass]="{'active': offres}" (click)="check()" class="dropdown-toggle">
                <span class="mif-star-full"></span> {{'Nos offres' | traduction:langue }}
            </a>
            <ul style="z-index: 2000;" class="d-menu" data-role="dropdown" style="background-color: #303030;">
                <li>
                    <a routerLink="/offres/sejour" routerLinkActive="active">
                        {{'Séjours' | traduction:langue }}
                    </a>
                </li>
                <li>
                    <a routerLink="/offres/hebergement" routerLinkActive="active">
                         {{'Hébergements' | traduction:langue }}
                    </a>
                </li>
                <li>
                    <a routerLink="/offres/transport" routerLinkActive="active">
                        {{'Transports' | traduction:langue }}
                    </a>
                </li>
                <li>
                    <a routerLink="/offres/divertissement" routerLinkActive="active">
                        {{'Divertissements' | traduction:langue }}
                    </a>
                </li>
            </ul>
        </li>
        <li>
            <a routerLink="panier" routerLinkActive="active">
                <span class="mif-cart"></span> {{'Panier' | traduction:langue }}
                <b class="superieur" style="margin-left: 3px;">
                    <sup>{{reservations.length}}</sup>
                </b>
            </a>
        </li>

        <li *ngIf="!utilisateur && !admin">
            <a routerLink="/connexion" routerLinkActive="active">
                <span class="mif-security"></span> {{'Connexion' | traduction:langue }}
            </a>
        </li>
        <li *ngIf="utilisateur">
            <a class="dropdown-toggle" [ngClass]="{'active': dashboard}">

                <span class="nom-user">
                    <i *ngIf="mobile" class="mif-user" style="margin-right: 10px;"></i>
                    <ng-container *ngIf="utilisateur.nom && !utilisateur.displayName">
                        {{utilisateur ? utilisateur.nom :'Mon'}}
                        {{utilisateur ? utilisateur.prenom :'compte'}}
                    </ng-container>
                    <ng-container *ngIf="utilisateur.displayName">
                        {{utilisateur.displayName}}
                    </ng-container>
                    
                </span>
                <img [src]="photoURL" class="user-image">
            </a>
            <ul style="background-color: #303030; z-index: 2000; margin-left: 0px; padding-left: 0px;" class="d-menu" data-role="dropdown">
                <li>
                    <a routerLink="/dashboard/profil" routerLinkActive="active">
                        {{'Mon profil' | traduction:langue }}
                    </a>
                </li>
                <li>
                    <a routerLink="/dashboard/compte" routerLinkActive="active">
                        {{'Mon compte' | traduction:langue }}
                    </a>
                </li>
                <li>
                    <a routerLink="/dashboard/reservation" routerLinkActive="active">
                        <ng-container *ngIf="!utilisateur.prestataire">
                            {{'Mes réservations' | traduction:langue }}
                        </ng-container>
                        <ng-container *ngIf="utilisateur.prestataire">
                            {{'Réservations' | traduction:langue }}
                        </ng-container>
                    </a>
                </li>
                <li *ngIf="utilisateur.prestataire">
                    <a [routerLink]="['offres', 'hebergement', 'prestataire', utilisateur.id]">
                        {{'Mes offres' | traduction:langue }}
                    </a>
                </li>
                <li>
                    <a routerLink="/dashboard/paiement" routerLinkActive="active">
                        <ng-container *ngIf="!utilisateur.prestataire">
                            {{'Mes paiements' | traduction:langue }}
                        </ng-container>
                        <ng-container *ngIf="utilisateur.prestataire">
                            {{'Paiements' | traduction:langue }}
                        </ng-container>
                    </a>
                </li>
                <li>
                    <a (click)="deconnexion()">
                        {{'Déconnexion' | traduction:langue }}
                    </a>
                </li>
            </ul>
        </li>
        <li>
            <a class="dropdown-toggle" [ngClass]="{'active': dashboard}">

                <span class="nom-user">
                    
                    {{langue}}
                    
                </span>
                <img src="../../../assets/img/ciel.jpg" style="opacity: 0; width: 0px;" class="user-image" alt="">
            </a>
            <ul style="background-color: #303030; z-index: 2000; margin-left: 0px; padding-left: 0px;" class="d-menu" data-role="dropdown">
                <li>
                    <a (click)="changerDeLangue('FR')">
                        FR
                    </a>
                </li>
                <li>
                    <a (click)="changerDeLangue('ENG')">
                        ENG
                    </a>
                </li>
            </ul>
        </li>
        <li class="muanza-padding-droit">
            <a class="dropdown-toggle" [ngClass]="{'active': dashboard}">

                <span class="nom-user">
                    
                    {{devise}}
                    
                </span>
                <img src="../../../assets/img/ciel.jpg" style="opacity: 0; width: 0px;" class="user-image" alt="">
            </a>
            <ul style="background-color: #303030; z-index: 2000; margin-left: 0px; padding-left: 0px;" class="d-menu" data-role="dropdown">
                <li>
                    <a (click)="changerDeDevise('EUR')">
                        EUR
                    </a>
                </li>
                <li>
                    <a (click)="changerDeDevise('USD')">
                        USD
                    </a>
                </li>
                <li>
                    <a (click)="changerDeDevise('XAF')">
                        XAF
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</div>