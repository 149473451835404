<div style="min-height: 80vh; background-color: #eee;">

    <div *ngIf="paiement" style="text-align: center; height: 50vh; padding-top: 10vh;">

        <div style="margin-bottom: 20px">
            <span class="mif-checkmark" style="font-size: 12em; color: rgb(48, 164, 221);"></span>
        </div>

        <div style="margin-bottom: 10px">
            Votre paiement a bien été effectué
        </div>
        <p style="margin-bottom: 10px">
            Un email de confirmation vous sera envoyé. Regardez dans vos spams s'il n'apparait pas dans votre boîte principale
        </p>
        <div style="color: #aaa;margin-bottom: 10px">
            {{paiement.id}}
        </div>
        <div style="margin-bottom: 50px; font-size: 2.0em;">
            {{paiement.total | currency:'XAF':'symbol':'1.2-2':'fr-FR'}}
        </div>

        <div>
            <button routerLink="accueil" class="button" style="background-color: rgb(48, 164, 221); color: white">
                Revenir à l'accueil
            </button>
        </div>
    </div>

    <div *ngIf="!paiement" style="text-align: center; height: 50vh; padding-top: 10vh;">

        <div style="margin-bottom: 20px">
            <span class="mif-checkmark" style="font-size: 12em; color: rgb(48, 164, 221);"></span>
        </div>

        <div style="margin-bottom: 10px">
            Votre paiement a bien été effectué
        </div>
        <p style="margin-bottom: 10px">
            Un email de confirmation vous sera envoyé. Regardez dans vos spams s'il n'apparait pas dans votre boîte principale
        </p>

        <div>
            <button routerLink="accueil" class="button" style="background-color: rgb(48, 164, 221); color: white">
                Revenir à l'accueil
            </button>
        </div>
    </div>
</div>